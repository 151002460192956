.leadRequestWrapper {
    /* background-color: #ffcc00; */
    margin-top: 10px;
    border-radius: 10px 10px 0px 0px;
    padding: 7px 12px 10px 12px !important;
}
.requestTitle {
    color: var(--red);
    font-weight: 500;
    font-size: 14px;

    margin-bottom: 10px;
}

.requestContainer {
    padding: 10px;
    border: 1px solid lightgray;
    border-radius: 4px;
}

.requestContainer:not(:last-child) {
    margin-bottom: 8px;
}

.requestSub {
    font-weight: 500;
    font-size: 13px;
    margin-bottom: 5px;
}

.requestItem {
    white-space: normal;
    font-size: 12px;
    color: #515861;
    word-break: break-word;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.requestItem:not(:last-child){
    margin-bottom: 5px;
}

.actionButton {
    display: flex;
    gap: 4px;
}

.requestUser {
    max-width: 180px;
    font-weight: 500;
    color: var(--dark_blue);

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}