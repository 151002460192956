.contact-single-details-more-info-tab{
    padding: 0 .75rem;
    margin-bottom: 10px;
    width: 100%;
    margin-left: auto;
    left: auto;
    right: auto;
}
.contact-single-details-more-info-tab .contact-single-details-more-info-tab-body{
    width: 100%;
    background-color: var(--gray_light);
    border-radius: 6px;
    padding: 1rem !important;
}
.contact-single-details-more-info-tab .___more_info__each_info___item{
    display: flex;
    padding: 10px;
    background: #fff;
    /* border-radius: 6px; */
    /* margin-bottom: 5px; */
    align-items: center;
    border-bottom: 1px solid var(--gray_light);
}
.contact-single-details-more-info-tab .___more_info___icon {
    margin: 0px 10px;
    min-width: 30px;
}

.contact-single-details-more-info-tab span.age__calculate > span {
    display: block;
}
.contact-single-details-more-info-tab span.age__calculate > span,
.contact-single-details-more-info-tab .___more_info__title {
    font-size: 14px;
    color: var(--dark_blue);
}
.contact-single-details-more-info-tab span.age__calculate {
    margin-top: 6px;
    display: block;
}
.contact-single-details-more-info-tab span.age__calculate,
.contact-single-details-more-info-tab .___more_info__value {
    font-size: 12px;
    color: var(--gray);
    word-break: break-word;
}
.contact-single-details-more-info-tab .___more_info__value.no____data {
    font-size: 10px;
    color: var(--gray);
    opacity: 0.5;
    font-style: italic;
}
.contact-single-details-more-info-tab .___more_info__each_info___item:first-child{
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}
.contact-single-details-more-info-tab .___more_info__each_info___item:last-child{
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom: none !important;
}
.contact-single-details-more-info-tab .contact-single-details-more-info-header{
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    justify-content: space-between;
    padding-top: 15px;
    align-items: center;
}
.contact-single-details-more-info-tab .contact-single-details-more-info-header span{
    font-size: 16px;
    color: var(--dark_blue);
    font-weight: 500;
}
.contact-single-details-more-info-tab .contact-single-details-more-info-header div{ 
    font-size: 14px;
    padding: 5px 14px;
    cursor: pointer;
    background: #fff;
    border-radius: 4px;
    color: var(--accent-color);
}

@media (max-width: 1366px) {
    .contact-single-details-more-info-tab span.age__calculate, 
    .contact-single-details-more-info-tab .___more_info__value {
        font-size: 11px;
    }
}