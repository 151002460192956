.pre-loader{
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
    width: 100%;
    text-align: center;
}

/* LOADER  */

.pre-loader span{
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: #f1f1f1;
    margin: 3px 5px;
    opacity: 0;
}

.pre-loader span:nth-child(1){
    animation: opacitychange 1s ease-in-out infinite;
}

.pre-loader span:nth-child(2){
    animation: opacitychange 1s ease-in-out 0.33s infinite;
}

.pre-loader span:nth-child(3){
    animation: opacitychange 1s ease-in-out 0.66s infinite;
}

@keyframes opacitychange{
    0%, 100%{
        opacity: 0;
    }

    60%{
        opacity: 1;
    }
}