.ContactModule-container-parent-class .deal-each-list__inbox .deal-won-lost__inbox{
    display: flex;
    align-items: center;
}

.mt-10 {
    margin-top: 10px;
}

.ContactModule-container-parent-class .deal-each-list__inbox .i-deal-list-info__each{
    width: calc(100% - 167px);
    align-items: center;
    display: flex;
    position: relative;
}
/* .ContactModule-container-parent-class .i-deal-list-info__each .i-deal-title-alt{
    width: calc(100% - 80px);
} */
.ContactModule-container-parent-class .i-deal-list-info__each .i-deal-value__alt{
    font-size: 13px !important;
    text-align: center;
    color: var(--dark-blue);
}

.ContactModule-container-parent-class .deal-won-lost__inbox .each-deal-won-action-button,
.ContactModule-container-parent-class .deal-won-lost__inbox .each-deal-lost-action-button{
    padding: 2px;
    border-radius: 20px;
    justify-content: center;
    align-items: center;
    display: flex
  }

  .ContactModule-container-parent-class .deal-won-lost__inbox {
      font-size: 14px;
      margin-top: 4px;
  }

  .ContactModule-container-parent-class .deal-won-lost__inbox .small-round-icon{
      margin-top: 5px;
  }

  .ContactModule-container-parent-class .deal-won-lost__inbox .each-deal-won-action-button{
    border: 1px solid #6FCF97;
    margin-right: 4px;
  }
  .ContactModule-container-parent-class .deal-won-lost__inbox .each-deal-won-action-button span{
    color: #6FCF97 !important;
    margin-left: 1px;
  }
  .ContactModule-container-parent-class .deal-won-lost__inbox .each-deal-lost-action-button{
    border: 1px solid #EB5757;
  }
  .ContactModule-container-parent-class .deal-won-lost__inbox .each-deal-lost-action-button span{
    color: #EB5757 !important;
    margin-left: 3px;
  }

  .ContactModule-container-parent-class .contact-details-deals .user__tabs__title.MuiTab-root {
    min-width: 33% !important;
}

.ContactModule-container-parent-class .no-deal-div span{
    font-style: italic;
}

.ContactModule-container-parent-class .i-deal-list-info__each .i-deal-edit-icon{
    /* position: absolute;
    justify-content: center;
    align-items: center;
    display: flex;
    width: 100%;
    padding: 2px;
    background: #ffffff96; */

    display: flex;
    color: #3c7ef3;
    background: #e5e8ef;
    padding: 2px;
    margin-left: 5px;
    border-radius: 5px;
}
.ContactModule-container-parent-class .i-deal-list-info__each .i-deal-edit-icon svg{
    color: #3c7ef3;
}

.addd-campaigns-modal .upload-single-item-main .campaign-add-radio-list
{
    max-height: calc(100vh - 432px);
}
.addd-campaigns-modal .uneligible-list-contact-rightside .campaign-add-radio-list{
    max-height: calc(100vh - 524px);

}

.ContactModule-container-parent-class .inbox-right-side div span.MuiSkeleton-root{
    height: 50px;
}

.ContactModule-container-parent-class .inbox-right-side .MuiSkeleton-pulse {
    animation: MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite;
}

.ContactModule-container-parent-class .inbox-right-side .MuiSkeleton-text {
    transform: unset;
}


.ContactModule-container-parent-class .deal-each-list__inbox:not(:last-child){
    border-bottom: 1px solid #89a4a4;
    padding-bottom: 10px;

}

.ContactModule-container-parent-class .each-deal-won-action-button, .each-deal-lost-action-button {
    font-size: 12px;
}

.ContactModule-container-parent-class .i-deal-value__alt {
    font-size: 14px;
}
.ContactModule-container-parent-class .deal_each_list_inbox_extra_new{
    flex-shrink: 0;
    font-size: 12px;
    /*width: 80%;*/
}
.ContactModule-container-parent-class .deal_each_list_inbox_extra .deal_each_list_inbox_extra_svg{
    width: 11px;
}
.ContactModule-container-parent-class .deal_each_list_inbox_extra .deal_each_list_inbox_extra_won{
    font-size: 11px;
}
.ContactModule-container-parent-class .deal_each_list_inbox_extra .deal_each_list_inbox_extra_edit svg{
    width: 12px;
}
.ContactModule-container-parent-class .deal_each_list_inbox_extra .deal_each_list_inbox_extra_edit{
    border: 1px solid #ddd !important;
    background: var(--white) !important;
    font-size: 12px;
    color: var(--dark_blue);
    font-weight: 500;
    width: 58px !important;
    border-radius: 30px !important;
    padding: 2px 0 !important;
    display: inline-flex;
    grid-gap: 2px;
    height: 100%;
    margin: 0;
}

