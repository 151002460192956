.ContactModule-container-parent-class .inactive-contact-lists {
  margin: 0;
  padding: 0;
}

.ContactModule-container-parent-class .inactive-contact-lists .row {
  display: flex;
  /* flex: 1; */
  flex-direction: row;
  justify-content: space-between;
  margin: 0 !important;
}

.ContactModule-container-parent-class
  .inactive-contact-lists
  .row
  .dropdown
  .dropdown-row {
  display: flex;
  flex-direction: row-reverse;
  /* justify-content: space-between; */
}

.ContactModule-container-parent-class
  .inactive-contact-lists
  .row
  .dropdown
  .dropdown-row
  span {
  margin-top: 12px;
}

.ContactModule-container-parent-class
  .inactive-contact-lists
  .inactive__contact__list__table {
  border: none;
  margin-top: 0px;
}

/* .inactive-contact-lists .row .dropdown .refresh-button {
  margin-left:60px;  
} */

/* pagination */

.ContactModule-container-parent-class .inactive-contact-lists .pagination_bar {
  /* padding: 62px 0 20px; */
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .pagination_bar
  ul {
  display: flex;
  justify-content: center;
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .pagination_bar
  ul
  li
  a {
  color: #fff;
  background: #3c7ef3;
  border-radius: 5px;
  padding: 6px 13px;
  margin-left: 10px;
  display: block;
  border: 2px solid #3c7ef3;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .pagination_bar
  ul
  li:nth-child(2)
  a {
  background: transparent;
  border: 2px solid #3c7ef3;
  color: #3c7ef3;
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .pagination_bar
  ul
  li:nth-child(3)
  a {
  background: transparent;
  color: #3c7ef3;
  border: unset;
  padding: 0px 6px;
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .pagination_bar
  ul
  li:nth-child(3)
  a
  span {
  margin-right: 5px;
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .pagination_position {
  margin: 20px auto;
  /* position: fixed;
    bottom: 0;
    left: 25%;
    right: 20%; */
}

.ContactModule-container-parent-class .inactive-contact-lists .dropdown {
  width: 100%;
}
/* .ContactModule-container-parent-class .inactive-contact-lists .refresh-button {
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
} */
.ContactModule-container-parent-class .inactive-contact-lists .dropdown select {
  border: 1px solid rgba(19, 49, 89, 0.35);
  color: rgba(19, 49, 89, 0.65);
  font-size: 16px;
  width: 65px;
  margin: 0 10px;
  border-radius: 5px;
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .refresh-button
  button {
  padding: 8px 15px;
  background: #3c7ef3;
  border-radius: 5px;
  font-family: var(--poppins);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #fff;
  text-transform: capitalize;
}
.ContactModule-container-parent-class .inactive-contact-lists .heading {
  border-bottom: 1px solid #e5e8ef;
  margin: 0 0 12px 0;
  padding: 0 0 25px 0;
}
.ContactModule-container-parent-class .inactive-contact-lists .heading span {
  font-size: 20px;
  color: var(--dark_blue);
}

/* table design css */
.ContactModule-container-parent-class
  .inactive-contact-lists
  .inactive__contact__list__table
  td {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .data__export__comn__status {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  border-radius: 16px;
  padding: 4px 15px;
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .inactive-contact-list-status-pending {
  background-color: var(--orange);
}

.ContactModule-container-parent-class
  .inactive-contact-lists
  .inactive-contact-list-status-processing {
  background-color: var(--green2);
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .inactive-contact-list-status-complete {
  background-color: var(--blue-1);
}
.ContactModule-container-parent-class
  .inactive-contact-lists
  .inactive-contact-list-status-failed {
  background-color: var(--red);
}
.inactive-contact-list-file-table-empty,
.inactive-contact-list-file-table-loading {
  height: 100px;
}
.inactive-contact-list-file-table-empty td,
.inactive-contact-list-file-table-loading td {
  text-align: center;
}

.inactive-contact-list-heading-alt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 20px;
}
.inactive-contact-list-heading-alt .inactive-contact-list-back-button {
  padding: 5px 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
}
.inactive-contact-list-heading-alt .inactive-contact-list-back-button span {
  color: #fff !important;
  font-size: 18px;
  margin-left: 5px;
}
.export-status-complete {
  align-items: center;
  display: flex;
}
.export-file-download-icon {
  align-items: center;
  display: flex;
  margin-left: 10px;
}

.export-file-delete-icon {
  align-items: center;
  display: flex;
  margin-left: 10px;
  cursor: pointer;
}

.inactive-contact-list-heading-bread__alt {
  display: flex;
  align-items: center;
  grid-gap: 20px;
}
.inactive-contact-list-heading-bread__alt h3 {
  font-size: 20px;
}
.inactive-contact-list-bradcumb-container .inactive-contact-list-bradcumb-each {
  display: inline-flex;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.54);
}
.inactive-contact-list-bradcumb-container
  .inactive-contact-list-bradcumb-each:hover {
  text-decoration: underline;
  cursor: pointer;
}
.inactive-contact-list-bradcumb-container
  .inactive-contact-list-bradcumb-each:last-child {
  color: rgba(0, 0, 0, 0.87);
  text-decoration: none !important;
}

.inactive-contact-list-bradcumb-container
  ul.inactive-contact-list-bradcumb-list {
  padding: 0;
  display: inline-flex;
  align-items: center;
  grid-gap: 5px;
}

.inactive-contact-list-bradcumb-container {
}

.inactive-contact-lists .input__field__show__pagination {
  margin-left: 0;
}

.inactive-contact-lists .inactive_contact_field_email {
  width: "30%";
  text-transform: lowercase;
}
