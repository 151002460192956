.email__droupdown{
    width: 200px;
    background: #f7f9fa;
    font-size: 14px;
    text-align: left;
    border-radius: 3px;
    box-shadow: 0 0 8px rgb(129 152 171 / 44%);
    transition: .1s;
    z-index: 10;
    padding: 15px;
}
.email__droupdown__content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.email__deroupdoan__head{
    padding: 2px 0 12px 0px;
    text-transform: uppercase;
    font-size: 90%;
    color: #888;
    letter-spacing: .4px;
    margin: 0;
}

.email__pophover__wr .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(0,0,0,0.0);
    border-width: 0px;
}
.email__pophover__wr .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0,0,0,0.0);
}
.business_select .MuiSelect-select:focus{
    border-radius: 6px;
}
.MuiFormControl-root.email__pophover__wr {
    width: 100%;
}
.contact_list_toggle_input input{
    padding: 10px 10px !important;
    width: 100% !important;
    display: block;
    font-size: 1rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff !important;
    background-clip: padding-box;
    border: 1px solid #ced4da !important;
    border-radius: .25rem !important;
    font-size: 16px !important;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    border-radius: 6px;
    margin: 6px 0 6px 0 !important;
    height: 40px !important;
}
.email__pophover__wr .MuiOutlinedInput-input{
    padding: 10px;
    width: 100% !important;
    display: block;
    font-size: 1rem;
    line-height: 1.25;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}
.email__pophover__wr .MuiSelect-select:focus{
    background-color: transparent !important;
}
.email__pophover__wr .MuiInputBase-formControl{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.16px;
    border-radius: 6px;
}

button.contact_list_toggle_btn {
    width: 100%;
    text-align: left !important;
    background: var(--light_blue);
    color: var(--white) !important;
    padding: 10px 8px !important;
}

.contact_list_toggle_btn.MuiButton-root:hover {
    text-decoration: none;
    background-color: var(--light_blue);
}

.toggle__icon__email {
    text-align: right !important;
    float: right;
    cursor: pointer;
}

.pop__hover__email{
    width: 100% !important;
}

/* Global Css Customize */
div#column-1 {
    width: 100% !important;
}
.sc-bBjRSN.kLdQFI{
    width: 100%;
}




.email__droupdown__content {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
    flex-wrap: wrap;
    flex-direction: row;
}

.email__deroupdoan__head + .MuiFormControl-root.email__pophover__wr {
    width: 100%;
}


.email__deroupdoan__head + .MuiFormControl-root.email__pophover__wr {
    width: 100%;
}

.contact_list_toggle_input_wr {
    display: inline-flex;
    flex-basis: calc(40% - 15px);
}

.contact_list_toggle_input_wr + .MuiFormControl-root.email__pophover__wr {
    flex-basis: calc(60% - 5px);
    width: auto;
    flex-direction: unset;
}
.input_select_inline{
    display: unset;
    flex-basis: unset;
}