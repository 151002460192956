.pageWrapper {

}

.pageHeader {
    width: 100%;
    background-color: #edf5ff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #e5eff3;
    border-bottom: 0;
}

.pageHeaderTitle {
    line-height: 1.9;
    display: flex;
    align-items: center;
    gap: 15px;
}

.pageMainTitle {
    color: #3a414a !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 24px !important;
    text-transform: uppercase !important;
    font-weight: 600 !important;
}

.pageMainSubtitle {
    color: #3a414a !important;
    font-size: 15px !important;
    padding: 0 !important;
    margin: 0 !important;
}

.pageHeaderAction {
    display: flex;
    align-items: center;
    gap: 20px;
}

.formControl {
    width: 100%;
}

.formControl .MuiInputBase-root {
    color: black !important;
}

.pageBody {
    color: #3a414a;
    background: #ffffff;
    padding: 8px 16px;
    border: 1px solid #635dff;
    border-radius: 4px;
}

.tableHead {
    color: #3a414a;
    font-size: 17px;
}

.fileTitle {
    font-size: 16px;
    font-weight: 700;
}

.validationType {
    font-size: 14px;
}

.validationTypeLabel {
    font-size: 16px;
    font-weight: 700;
}

.fileId {
    font-size: 14px;
}

.fontBold {
    font-weight: 700;
}

.statusChip {
    background-color: #00c2a8;
    color: #ffffff;
    padding: 4px 8px;
    border-radius: 14px;
    font-size: 14px;
}

.viewDetails {
    background-color: #d9e3eb;
    padding: 6px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    cursor: default;
}

.fileActions {
    display: flex;
    justify-content: space-between;
}

.downloadButton {
    border-collapse: collapse;
    border-spacing: 0;
    overflow: visible;
    font-family: var(--poppins) !important;
    border: 0;
    cursor: pointer;
    margin: 0;
    outline: 0;
    position: relative;
    align-items: center;
    user-select: none;
    vertical-align: middle;
    justify-content: center;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    color: #fff;
    background-color: #3f51b5;
    -webkit-appearance: button;
    display: flex;
}

.textAlignCenter {
    text-align: center;
}

.detailsPane {

}

.detailsPaneHeader {
    display: flex;
    justify-content: space-between;
    padding: 10px 4px;
}

.detailsPaneHeaderLeft {
    width: 22%;
    display: flex;
    gap: 20px;
}

.detailsPaneHeaderRight {
    display: flex;
    width: 60%;
    justify-content: space-between;
    align-items: center;
}

.filterWrapper {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    align-items: center;
}

.pageBody table tbody tr:last-child {
    border: none;
}

.detailsPaneBody {

}

.detailsPaneBody table thead th {
    padding: 4px 6px;
    background-color: #c9e6e7;
}

.detailsPaneBody table tbody tr {
    border: none;
}

.detailsPaneBody table tbody td {

}

.dFlexAlignCenter {
    display: flex;
    align-items: center;
}

.backButton {
    width: 120px;
}

.loaderWrapper {
    padding: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.marginTop {
    margin-top: 100px;
}

.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.failedReason {
    font-size: 12px;
}

.cursorPointer {
    cursor: pointer;
}

.active {
    background-color: #4CAF50;
    color: #ffffff;
}

.queued {
    background-color: #FFC107;
    color: #333333;
}


.processing {
    background-color: #2196F3;
    color: #ffffff;
}

.complete {
    background-color: #8BC34A;
    color: #333333;
}


.failed {
    background-color: #F44336;
    color: #ffffff;
}

.default {
    background-color: #f2f2f2;
    color: #333333;
}