.contact_center_field .contact__middle__inbox__wrapper .inbox_loader {
  height: calc(100vh - 380px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -20px;
}


/* for conversation skeleton contact middle side*/
.contact_center_field .contact__middle__inbox__wrapper .inbox_loader .skeleton_each_conversation{
  display: flex;
  grid-gap: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  align-items: flex-end;
}
.contact_center_field .contact__middle__inbox__wrapper .inbox_loader .skeleton_each_conversation.skeleton_left {
  padding-left: 40px;
}
.contact_center_field .contact__middle__inbox__wrapper .inbox_loader .skeleton_each_conversation.skeleton_right {
  flex-direction: row-reverse;
  padding-right: 40px;
}
.contact_center_field .contact__middle__inbox__wrapper .inbox_loader .skeleton_each_conversation.skeleton_right .skeleton_conversation_details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
