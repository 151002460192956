.pageWrapper {

}

.pageHeader {

}

.pageTitle {
    font-size: 25px;
    padding: 0;
    margin: 14px 0;
    font-weight: 600;
}

.pageSubTitle {
    font-size: 15px;
    margin: 0;
    padding: 0;
}

.pageBody {
    border-top: 1px solid #a8cbd3;
    margin-top: 15px;
}

.pageMainRow {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.pageMainCol {
    flex-basis: 0;
    -webkit-box-flex: 1;
    flex-grow: 1;
    max-width: 100%;
}

.emailSection {
    padding: 40px 10px 0px 30px;
}

.phoneSection {
    padding: 40px 30px 0px 10px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.formGroup {
    background-color: #635dff;
    padding: 20px;
    border-radius: 10px;
}

.formGroup label {
    color: white;
    font-size: 16px;
    font-weight: 600;
}

.formControl {
    display: flex;
    gap: 20px;
    margin-top: 15px;
}

.formInput {
    border: none !important;
    background-color: white !important;
    border-radius: 4px !important;
    padding: 10px !important;
}

.formInputTextArea {
    border: none !important;
    background-color: white !important;
    border-radius: 4px !important;
    padding: 10px !important;
    resize: none;
    height: 150px;
}

.formInputTextArea:focus-visible {
    outline: none;
}

.verifyButton {
    height: 3rem;
    color: white;
    border-radius: 12px;
    background-color: #00c2a8;
    border: 0;
    width: 160px;
}

.vl {
    border-left: 1px solid #71d5d5;
    margin: 15px 0 15px 0;
}

.flexAlignEnd {
    align-items: flex-end;
}