/* ====================== Global CSS ======================*/

.ContactModule-container-parent-class .MuiSelect-select:focus {
    background-color: transparent !important;
}

/* .ContactModule-container-parent-class .import_content_container { */
    /* margin: 10px 10px 10px 10px; */
    /* border-radius: 10px; */
    /* min-height: 884px; */
/* } */

.main_content_inner_container {
    /* max-width: 1602px; */
    margin: auto;
    border-radius: 10px;
    /* min-height: 884px; */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
}

/* .import-files tr td .MuiCollapse-entered {
    padding-top: 20px;
    padding-bottom: 20px;
} */

body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .import_content_container {
    /* max-width: 1770px; */
}

body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .import_content_container .main_content_inner_container {
    /* max-width: 1770px; */
}

.ContactModule-container-parent-class .import_content_container h6 {
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    color: var(--dark_blue);
    margin: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
}

.ContactModule-container-parent-class .import_content_container h6 small {
    font-size: 14px;
    margin-left: 12px;
}

.ContactModule-container-parent-class .import_content_container p {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #ADB7C5;
    margin: 0;
}

.main_content_container_button {
    padding: 13px 20px;
    background-color: var(--accent-color);
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    text-transform: capitalize;
    color: var(--accent-text-color) !important;
}

.main_content_container_button_icon {
    margin-right: 10px;
}

/* ======================== Main Content Topbar CSS =======================*/

.main_content_topbar {
    padding: 20px;
}

.main_content_topbar_content {
    height: 50px;
}

/* ======================== Main Content Divider CSS ====================*/

.main_content_container_divider {
    height: 2px;
    background-color: var(--gray_dark);
}

/*========================== Main Content Main Section CSS ==================*/

.main_content_main_section {
    height: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px 0;
}

.main_content_main_section_content {
    margin: auto;
    /* margin-top: 218px; */
    width: 500px;
    height: auto;
    outline: none;
    border: none;
}

.main_content_main_section_heading {
    margin-bottom: 10px;
}

.main_content_main_section_details {
    margin-bottom: 35px !important;
}

.main_content_main_section_button_group {
    padding: 0 6px;
    width: 100%;
}

/*========================== Media Query Main Content Main Section CSS ====================*/

@media(max-width: 1600px) {
    body .table_data_5 .table_action_buttons button p {
        font-size: 12px;
    }
    body .table_data_3 span {
        font-size: 16px !important;
        margin-left: 5px;
    }
    body .import_list_table table thead tr th:last-child {
        width: 280px;
    }
    body .import_list_table table thead th {
        font-size: 16px;
    }
}

@media(max-width: 1500px) {
    body .table_data_3 span {
        font-size: 14px !important;
    }
    body .table_data_4 div h6 {
        font-size: 14px;
    }
    body .outline_button {
        font-size: 14px;
    }
    body .import_list_table table thead tr th:last-child {
        width: 110px;
    }
    body .import_list_table table thead th:nth-child(3) {
        width: 200px;
    }
}

@media(max-width: 1200px) {
    body .process_number_names {
        font-size: 14px;
    }
    body .done_step {
        height: 40px;
        width: 40px;
    }
    body .done_step svg {
        margin-top: 10px;
        width: 20px;
    }
    body .number {
        height: 40px;
        width: 40px;
        font-size: 25px;
        line-height: 40px;
    }
    body .duplicate_rule_content_section {
        padding: 0 40px;
    }
}

@media (max-width: 1199px) {
    .main_content_topbar {
    padding: 5px 20px;
}
body .importButton:hover, 
body .importButton { 
    padding: 5px; 
    min-width: 135px; 
    font-size: 12px;
    line-height: 26px;
}
body .main_content_topbar {
    padding: 5px 10px;
}
}
@media only screen and (min-width: 993px) {
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_content_container {
        margin-left: 30px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .main_content_main_section_content {
        /* margin-top: 274px; */
    }
}

@media only screen and (max-width: 992px) {
    .main__content {
        width: calc(100% - 40px);
    }
    /* .main__content .import_content_container {
        margin-left: 268px;
    } */
    /* body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_content_container {
        margin: 20px 0 0 0 !important;
    } */
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_content_container {
        margin-left: 30px;
    }
    /* body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .main_content_main_section_content {
        margin-top: 274px;
    } */
}

@media only screen and (max-width: 768px) {
    /* .main__content .import_content_container {
        margin: 20px 0 0 0;
    } */
    .main_content_main_section_content {
        /* margin-top: 207px; */
        width: 438px;
    }
    .ContactModule-container-parent-class .import_content_container h6 {
        font-size: 16px;
    }
    .ContactModule-container-parent-class .import_content_container p {
        font-size: 14px;
    }
    .main_content_container_button {
        font-size: 12px;
        padding: 8px;
    }
    .main_content_main_section_button_group {
        padding: 0 45px;
    }
}

/* ================= Numbering Section Css Global ===================== */

.ContactModule-container-parent-class .process_number_section {
    padding: 0 20px 30px 20px;
    width: 100%;
    max-width: 950px;
    margin: auto;
}

.ContactModule-container-parent-class .process_number_inner_section {
    margin: auto;
    /* max-width: 1358px; */
}

.ContactModule-container-parent-class .process_number_box {
    display: block;
    padding: 0 11px 0 17px;
}

.ContactModule-container-parent-class .process_number_box .number_box {
    display: block;
    /* height: 50px; */
    width: 50px;
    border-radius: 50%;
    margin: 0 10px;
}

.ContactModule-container-parent-class .done_step {
    display: none;
    background-color: #3C7EF3;
    height: 50px;
    width: 50px;
    border-radius: 50%;
}

.ContactModule-container-parent-class .done_step svg {
    margin-top: 14px;
}

.ContactModule-container-parent-class .number {
    display: block;
    background-color: #FFFFFF;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: 1px solid rgba(19, 49, 89, 0.35);
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    letter-spacing: 0.01em;
    color: rgba(19, 49, 89, 0.35);
}

.ContactModule-container-parent-class .number_checked .number {
    display: none;
}

.ContactModule-container-parent-class .number_checked .done_step {
    display: block;
}

.ContactModule-container-parent-class .process_number_box hr {
    width: 100%;
    height: 3px;
    background-color: rgba(19, 49, 89, 0.35);
    border: none;
}

.ContactModule-container-parent-class .line_checked {
    background-color: var(--light_blue) !important;
}

.ContactModule-container-parent-class .process_number_names {
    padding: 16px 0 0 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: var(--gray);
}

.ContactModule-container-parent-class .number_name1, .number_name6 {
    width: 13%;
    text-align: center;
}
.ContactModule-container-parent-class .last-item.number_name6 {
    text-align: right;
}
.ContactModule-container-parent-class .number_name {
    width: 18.5%;
    text-align: center;
}

.ContactModule-container-parent-class .name_checked {
    color: var(--light_blue);
}

/* ========================== Divider =======================*/

.ContactModule-container-parent-class .content_divider {
    /* max-width: 1601px; */
    width: 100%;
    height: auto;
    margin: auto;
}

.ContactModule-container-parent-class .content_divider_inner {
    /* max-width: 1561px; */
    width: 100%;
    height: 2px;
    margin: 0 20px;
    background: rgba(229, 232, 239, 0.5);
}

/* ================= Media Query Numbering Section Css Global ===================== */

@media only screen and (max-width: 1366px) {
    /* .process_number_section{
        padding: 20px 53px 20px 53px;
    } */
    .ContactModule-container-parent-class .process_number_box {
        padding: 0 5px 0 17px;
    }
    .ContactModule-container-parent-class .number_name1 {
        width: 14.5%;
    }
    .ContactModule-container-parent-class .number_name6 {
        width: 13%;
    }
    .ContactModule-container-parent-class .number_name {
        width: 18%;
        text-align: center;
    }
    body .table_data_1 p {
        width: 140px;
        font-size: 13px;
    }
    body .table_data_3 span {
        font-size: 12px !important;
    }
    body .import_list_table table thead th:nth-child(3) {
        width: 150px;
    }
    .ContactModule-container-parent-class .import_list_table table thead th p {
        font-size: 10px;
    }
    body .outline_button {
        font-size: 12px;
        padding: 6px;
    }
    body .table_data_4 div h6 {
        font-size: 12px;
    }
    body .table_data_4 div h6 {
        font-size: 12px;
    }
    
    body .import_list_table table thead th {
    font-size: 14px;
}
}

@media only screen and (max-width: 992px) {
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .process_number_box {
        padding: 0 0 0 7px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .process_number_box .number_box {
        width: 40px !important;
        height: 40px !important;
        margin: 0 5px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .process_number_box .done_step {
        width: 40px !important;
        height: 40px !important;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .process_number_box .done_step svg {
        margin-top: 11px;
        width: 22px;
        height: 17px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .process_number_box .number {
        width: 40px !important;
        height: 40px !important;
        font-size: 32px;
        line-height: 38px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .process_number_names {
        padding: 9px 0 36px 0;
        font-size: 13px;
        line-height: 15px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .number_name {
        width: 18%;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .number_name1 {
        width: 15.5%;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .number_name6 {
        width: 12.5%;
    }
}

@media only screen and (max-width: 768px) {
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .process_number_section {
        padding: 30px 40px 0 41px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .process_number_names {
        padding: 9px 0 36px 0;
        font-size: 10px;
        line-height: 15px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .number_name {
        width: 18%;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .number_name1 {
        width: 15.5%;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .number_name6 {
        width: 12.5%;
    }
}

/* ===================== Upload Section css ====================== */

.main_contain_detail_section {
    margin: auto;
    /* margin-top: 88px; */
    margin: 40px auto;
    /* width: 375px; */
    max-width: 375px;
}

.ContactModule-container-parent-class .upload_section {
    margin-top: 21px;
    width: 100%;
    height: auto;
    background: var(--gray_light);
    border-radius: 10px;
    padding: 10px 12px 10px 11px;
}

.ContactModule-container-parent-class .upload_inner_section {
    flex-direction: column;
    border: 1px dashed var(--light_blue);
    border-radius: 5px;
    height: 160px;
}

.ContactModule-container-parent-class .upload_inner_section_content {
    margin: 21px 98px;
}

.ContactModule-container-parent-class .upload_inner_section_content .upload_logo {
    height: 30px;
}

.ContactModule-container-parent-class .upload_inner_section_content .details {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: var(--dark_blue);
    width: 156px;
}

.ContactModule-container-parent-class .upload_inner_section_content .input-field {
    width: 148px;
    margin: 0 0 0 4px;
}

.ContactModule-container-parent-class .browse_file_button {
    padding: 8px 0px;
    background: var(--gray_dark);
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--light_blue);
}

.ContactModule-container-parent-class .attention_text {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: var(--red);
    opacity: 0.8;
}

.ContactModule-container-parent-class .file-import-header {
    min-width: 150px;
}

.ContactModule-container-parent-class .react-confirm-alert-body h1 {
    font-size: 25px;
}

.ContactModule-container-parent-class .cursor-pointer {
    cursor: pointer;
}

/* ===================== Media Query Upload Section css ====================== */

@media only screen and (max-width: 768px) {
    .ContactModule-container-parent-class .upload_section {
        margin-top: 25px;
    }
    .ContactModule-container-parent-class .upload_inner_section_content {
        margin: 21px 109px;
    }
    .ContactModule-container-parent-class .upload_inner_section_content .details {
        font-size: 12px;
    }
    .ContactModule-container-parent-class .browse_file_button {
        padding: 8px 20px;
        font-size: 12px;
    }
    .ContactModule-container-parent-class .attention_text {
        font-size: 12px
    }
}

/*==================================================================
 Map Contact Page CSS 
 ===================================================================*/

.main__content .map_contact_page_container .main_content_main_section {
    border-radius: 0 0 10px 10px;
    margin-bottom: 20px;
}

.main__content .map_contact_page_container .main_content_topbar {
    border-radius: 10px 10px 0 0;
}

.ContactModule-container-parent-class .uploaded_file_details_section {
    padding: 20px 0 20px 28px;
    border-radius: 0 0 10px 10px;
}

.ContactModule-container-parent-class .uploaded_file_icon {
    margin-right: 10px;
    display: flex;
    align-items: center;
}

.ContactModule-container-parent-class .uploaded_file_details {
    margin-right: 35px;
}

.ContactModule-container-parent-class .uploaded_file_details p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: var(--dark_blue);
}

.ContactModule-container-parent-class .uploaded_file_details p span {
    color: var(--light_blue);
}

/* Tab Section Css */

.main_contain_detail_tab_section {
    /* height: 670px; */
    height: unset;
    width: 100%;
    border-radius: 10px 10px 0px 0px;
    padding: 0 2px 0 10px;
}

/* Tab Toggle Section */

.ContactModule-container-parent-class .tab_title {
    display: flex;
    align-items: center;
}

/* 
.tabs {
    height: 70px;
    border-radius: 10px;
    width: 100%;
}

.tabs .tab {
    width: 201px;
    height: 70px;
    text-transform: none;
    margin-right: 8px;
}

.tabs .indicator {
    height: 3px;
    background-color: var(--blue-2);
}

.tabs .tab a.active, .tabs .tab a.active:hover {
    background-color: transparent;
    color: #3C7EF3;
}

.tabs .tab a:focus, .tabs .tab a:focus.active {
    background-color: #FFFFFF;
    outline: none;
}

.tabs .tab a {
    color: rgba(19, 49, 89, 0.5);
    display: block;
    width: 100%;
    height: 100%;
    padding: 10px 0 0 10px;
    font-size: 24px;
    line-height: 41px;
}

.tabs .tab a:hover {
    color: rgba(19, 49, 89, 0.5);
}

.tabs .tab1 .active svg path:first-child {
    fill: var(--light_blue);
    fill-opacity: 1;
}

.tabs .tab2 {
    width: 279px;
} */

.ContactModule-container-parent-class .tab_icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
}

/* Tab Content Section */

#tab1, #tab2 {
    background-color: #FFFFFF;
    padding: 0 2px 0 0;
    /* max-width: 1590px; */
    max-width: 100%;
    /* height: 600px; */
    height: auto;
    border-radius: 10px;
}

body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content #tab1, body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content #tab2 {
    max-width: 1758px;
}

/* Table Css */

.ContactModule-container-parent-class .imported_table {
    background-color: var(--gray_light);
    padding: 0 20px;
    /* max-width: 1590px; */
    max-width: 100%;
    /* height: 510px; */
    height: auto;
    /* min-height: 510px; */
    margin-bottom: 22px;
    border-radius: 10px;
}

body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_table {
    max-width: 1758px;
}

.ContactModule-container-parent-class .imported_table thead tr {
    border-bottom: 0px;
}

.ContactModule-container-parent-class .imported_table tbody tr {
    border-bottom: 20px solid var(--gray_light);
}

.ContactModule-container-parent-class .imported_table tbody td {
    background-color: #fff;
    padding: 10px 0;
    height: 147px;
    height: auto;
}

.ContactModule-container-parent-class .imported_table tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
}

.ContactModule-container-parent-class .imported_table tbody tr td:last-child {
    border-radius: 0 5px 5px 0;
}

.ContactModule-container-parent-class .imported_table.mapped_table.awesome__scroll_bar .input-field input {
    height: 23px;
}

/* Table Head */

.ContactModule-container-parent-class .imported_table thead th {
    padding: 0 0 0 0;
    background-color: var(--gray_light);
    height: 60px;
}

.ContactModule-container-parent-class .imported_table thead th h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #133159;
}

.ContactModule-container-parent-class .imported_table .heading_icon {
    margin-left: 10px;
    display: flex;
    align-items: center;
}

.ContactModule-container-parent-class .imported_table .imported_heading {
    width: 100%;
    display: flex;
    justify-content: center;
}

.ContactModule-container-parent-class .imported_table th:first-child {
    width: 656px;
    border-right: 2px solid #fff;
}

.ContactModule-container-parent-class .imported_table th:nth-child(2) {
    width: 517px;
}

.ContactModule-container-parent-class .imported_table th:last-child {
    width: 358px;
    border-left: 2px solid #fff;
}

body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_table th:last-child {
    max-width: 501px;
}

/* scrollbar */

.ContactModule-container-parent-class .imported_table.awesome__scroll_bar::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 10px;
    background-color: rgba(60, 126, 243, 0.1);
}

.ContactModule-container-parent-class .imported_table.scroll__bar_mt-80::-webkit-scrollbar-track {
    margin-top: 80px;
}

.ContactModule-container-parent-class .imported_table.awesome__scroll_bar::-webkit-scrollbar {
    width: 12px;
}

body.active__minimal_sidebar .imported_table.awesome__scroll_bar::-webkit-scrollbar {
    width: 6px;
}

.ContactModule-container-parent-class .imported_table.awesome__scroll_bar::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: none;
    background-color: rgba(58, 98, 150, 0.5);
}

@media(max-width: 992px) {
    .ContactModule-container-parent-class .imported_table.awesome__scroll_bar::-webkit-scrollbar {
        width: 6px;
    }
}

/* Table Body Data */

.ContactModule-container-parent-class .imported_table [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
    border: 1px solid var(--light_blue);
    border-radius: 5px;
    top: 3px;
}

.ContactModule-container-parent-class .imported_table [type="checkbox"].filled-in:checked+span:not(.lever):after {
    border: none;
    background-color: var(--light_blue);
    top: 3px;
    border-radius: 5px;
}

.ContactModule-container-parent-class .imported_table [type="checkbox"].filled-in:checked+span:not(.lever):before {
    top: 4px;
    left: 3px;
    width: 6.5px;
    height: 10.5px;
}

.ContactModule-container-parent-class .imported_table label .skip_text {
    color: var(--dark_blue) !important;
    font-size: 14px;
    line-height: 28px;
    padding-left: 30px;
}

.ContactModule-container-parent-class .imported_map_details_box {
    width: 400px;
}

.ContactModule-container-parent-class .imported_map_details {
    margin-left: 32px;
    width: 100%;
}

.ContactModule-container-parent-class .name_with_form_box {
    width: 100%;
}

.ContactModule-container-parent-class .imported_map_details h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #133159;
}

.ContactModule-container-parent-class .imported_map_details p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
}

.ContactModule-container-parent-class .customFormControl {
    border: 1px solid #e5e8ef !important;
    border-radius: 6px !important;
    padding: 0 12px !important;
    box-shadow: unset !important;
    width: 100% !important;
    box-sizing: border-box !important;
    margin: 0 !important;
}

.ContactModule-container-parent-class .campaign-search-results.alt {
    height: 350px;
    padding-bottom: 0;
}

.ContactModule-container-parent-class .campaign-search-results.alt .MuiCheckbox-colorSecondary.Mui-checked {
    color: rgba(19, 49, 89, 0.65);
}

.ContactModule-container-parent-class .tagsList__wrapper {
    text-align: center;
    margin: 35px auto;
    background: #f8f8f8;
    padding: 12px;
    border-radius: 8px;
    max-width: 70%;
    margin-bottom: 20px;
}

.ContactModule-container-parent-class .tagsList__wrapper h3 {
    font-size: 24px;
    margin: 0 0 20px;
}

.ContactModule-container-parent-class .tagsList__wrapper .MuiChip-root .MuiChip-avatar {
    width: 20px;
    height: 20px;
}

.ContactModule-container-parent-class .tagsList__wrapper .MuiChip-root {
    margin: 6px;
}

.ContactModule-container-parent-class .imported_table table tbody {
    /* height: 450px; */
    height: auto;
}

.ContactModule-container-parent-class .imported_table table tbody tr:first-child .input-field .select-wrapper input.select-dropdown {
    width: 159px;
}

.ContactModule-container-parent-class .imported_table table tbody tr:nth-child(2) .input-field .select-wrapper input.select-dropdown {
    width: 83px;
}

.ContactModule-container-parent-class .imported_table table tbody tr:nth-child(3) .input-field .select-wrapper input.select-dropdown {
    width: 142px;
}

.ContactModule-container-parent-class .action_table table tbody tr .input-field .select-wrapper input.select-dropdown {
    width: 154px !important;
}

.ContactModule-container-parent-class .imported_table table tbody tr .input-field .select-wrapper ul {
    width: 171px !important;
}

.ContactModule-container-parent-class .imported_table .input-field {
    margin: 0 0 0 15px;
    height: 30px;
}
.ContactModule-container-parent-class .imported_table .input-field {
    height: auto;
}
.ContactModule-container-parent-class .file-import-header {
    min-width: 180px;
}
.ContactModule-container-parent-class .imported_table .select-wrapper::after {
    position: absolute;
    content: '';
    border-top: 0px;
    border-left: 0px;
    display: block;
    width: 9px;
    height: 9px;
    top: 50%;
    right: 11px;
    margin-top: -3px;
    border-bottom: 2px solid #667993;
    border-right: 2px solid #667993;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: 50% 0;
}

.ContactModule-container-parent-class .imported_table .select-wrapper svg {
    display: none;
}

.ContactModule-container-parent-class .imported_table .select-wrapper input.select-dropdown {
    border: 1px solid #E5E8EF;
    margin: 0;
    padding: 0 0 0 10px;
    height: auto;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #546376;
    opacity: 0.8;
    border-radius: 5px;
}

.ContactModule-container-parent-class .imported_table .dropdown-content li {
    color: rgba(19, 49, 89, 0.35);
    min-height: auto;
    line-height: 30px;
    width: 100%;
    text-align: left;
    border-bottom: 0.5px solid #F6F7FB;
}

.ContactModule-container-parent-class .imported_table .select-dropdown.dropdown-content li:hover {
    background: rgba(60, 126, 243, 0.1);
    border-left: 4px solid #3C7EF3;
}

.ContactModule-container-parent-class .imported_table .select-dropdown.dropdown-content li:hover span {
    color: #3C7EF3;
}

.ContactModule-container-parent-class .imported_table .dropdown-content li>span {
    display: block;
    padding: 0 0 0 26px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: rgba(19, 49, 89, 0.35);
}

.ContactModule-container-parent-class .mapped_table tbody tr:first-child .dropdown-content li:nth-child(1)>span, .mapped_table tbody tr:first-child .dropdown-content li:nth-child(2)>span {
    color: var(--dark_blue);
    padding-left: 12px;
    cursor: not-allowed;
}

.ContactModule-container-parent-class .first_tdata {
    padding: 0 20px;
    width: 100%;
}

.ContactModule-container-parent-class .second_tdata {
    height: 100px;
    width: 100%;
    padding: 0 50px;
}

.ContactModule-container-parent-class .second_tdata p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
}

.ContactModule-container-parent-class .third_tdata {
    width: 100%;
    padding-left: 106px;
}

.ContactModule-container-parent-class .third_tdata .imported_map_details {
    margin-left: 0px;
}

.ContactModule-container-parent-class .action_table tbody tr td .imported_map_details p {
    color: #546376;
    opacity: 0.8;
}

.ContactModule-container-parent-class .action_table .first_tdata {
    padding: 0 50px 0 109px;
}

.main_contain_detail_tab_section .content_duel_buttons {
    position: static;
}

/* Process Mode */

.ContactModule-container-parent-class .create_deal_content_section .content_duel_buttons {
    /* position: static; */
    /* padding-bottom: 20px; */
}

.ContactModule-container-parent-class .create_deal_content_section .process__drip_mode {
    max-width: 70%;
    /* padding: 40px 0; */
}

.ContactModule-container-parent-class .create_deal_content_section label.form-control-label {
    font-size: 19px;
    /* margin-bottom: 20px; */
    display: inline-block;
    color: #000;
}

.ContactModule-container-parent-class label span.text-danger {
    font-size: 16px;
}

.ContactModule-container-parent-class .batch__repeat {
    grid-column-gap: 20px;
    column-gap: 20px;
    max-width: 575px;
}

.ContactModule-container-parent-class .create_deal_content_section .batch__repeat label.form-control-label {
    margin-bottom: 5px;
    font-size: 16px;
}

.ContactModule-container-parent-class .contact__middle__inbox__ul {
    margin-left: 20px;
    width: calc(100% - 20px);
}

.ContactModule-container-parent-class .sendOnButtons button {
    box-shadow: unset !important;
    border-radius: 6px;
    margin: 6px !important;
    background: #adadad80;
    color: #133159;
    padding: 5px 20px;
    height: unset;
    transition: all 0.3s ease-in;
}

.sendOnButtons button:focus {
    background: #cbcbcb !important;
}

.ContactModule-container-parent-class .sendOnButtons button.days-button, 
.sendOnButtons button.active, .sendOnButtons button:hover,
.sendOnButtons button.active, .sendOnButtons button.active:focus {
    background: #3c7ef3 !important;
    color: #fff;
}

.ContactModule-container-parent-class .importButton:hover,
.ContactModule-container-parent-class .importButton {
    border-radius: 6px;
    padding: 8px 12px;
    height: auto;
    min-width: 155px;
    box-shadow: unset;
    background: var(--accent-color);
    color: var(--accent-text-color);
    transition: all 0.3s ease-in;
    display: flex;
    align-items: center;
}

.ContactModule-container-parent-class .importButton svg {
    margin-right: 10px;
}

.ContactModule-container-parent-class .importButton:disabled {
    color: #133159 !important;
}

/* ========================== Media Query Map Contact Page Css */

@media only screen and (max-width: 1700px) {
    .ContactModule-container-parent-class .imported_map_details {
        margin-left: 23px;
    }
    .ContactModule-container-parent-class .imported_map_details_box {
        width: 380px;
    }
    .ContactModule-container-parent-class .second_tdata {
        padding: 0 22px;
    }
    .ContactModule-container-parent-class .third_tdata {
        padding-left: 50px;
    }
}

@media only screen and (max-width: 1550px) {
    .ContactModule-container-parent-class .imported_map_details {
        margin-left: 23px;
    }
    .ContactModule-container-parent-class .imported_map_details_box {
        width: 340px;
    }
    .ContactModule-container-parent-class .second_tdata {
        padding: 0 22px;
    }
    .ContactModule-container-parent-class .third_tdata {
        padding-left: 40px;
    }
}

@media only screen and (max-width: 1450px) {
    .ContactModule-container-parent-class .imported_table th:first-child {
        width: 380px;
    }
    .ContactModule-container-parent-class .imported_map_details {
        margin-left: 23px;
    }
    .ContactModule-container-parent-class .imported_map_details_box {
        width: 300px;
    }
    .ContactModule-container-parent-class .second_tdata {
        padding: 0 22px;
    }
    .ContactModule-container-parent-class .third_tdata {
        padding-left: 50px;
    }
}

@media only screen and (max-width: 1366px) {
    .ContactModule-container-parent-class .imported_table {
        padding: 0 14px 0 10px;
    }
    .ContactModule-container-parent-class .imported_table th:first-child {
        width: 424px;
    }
    .ContactModule-container-parent-class .imported_table th:nth-child(2) {
        width: 361px;
    }
    .ContactModule-container-parent-class .imported_table th:last-child {
        width: 216px;
    }
    .ContactModule-container-parent-class .imported_map_details {
        margin-left: 23px;
    }
    .ContactModule-container-parent-class .imported_map_details_box {
        width: 317px;
    }
    .ContactModule-container-parent-class .second_tdata {
        padding: 0 22px;
    }
    .ContactModule-container-parent-class .second_tdata p {
        text-align: left !important;
    }
    .ContactModule-container-parent-class .mapped_table table tbody tr:not(:first-child) .second_tdata {
        padding: 0 32px 0 22px;
    }
    .ContactModule-container-parent-class .third_tdata {
        padding-left: 25px;
    }
    body .import_list_table table thead tr th:last-child {
        width: 90px;
    }
    .ContactModule-container-parent-class .table_data_2 span {
    font-size: 12px;
} 
}

@media only screen and (max-width: 1250px) {
    .ContactModule-container-parent-class .imported_table th:first-child {
        width: 370px;
    }
    .ContactModule-container-parent-class .imported_table thead th h6 {
        font-size: 14px;
    }
    .ContactModule-container-parent-class .imported_table label .skip_text {
        font-size: 13px;
    }
    .ContactModule-container-parent-class .imported_map_details h6 {
        font-size: 13px;
    }
    .ContactModule-container-parent-class .imported_map_details p {
        font-size: 13px;
    }
    .ContactModule-container-parent-class .imported_table .select-selected {
        font-size: 13px;
    }
    .ContactModule-container-parent-class .imported_table form {
        margin-left: 8px;
    }
    .ContactModule-container-parent-class .second_tdata p {
        font-size: 12px;
        line-height: 18px;
        text-align: center;
    }
    .ContactModule-container-parent-class .imported_map_details {
        margin-left: 15px;
    }
    .ContactModule-container-parent-class .imported_map_details_box {
        width: 260px;
    }
    .ContactModule-container-parent-class .second_tdata {
        padding: 0 0 0 20px;
    }
    .ContactModule-container-parent-class .imported_table table tbody tr:not(:first-child) .second_tdata {
        padding: 0 20px 0 20px;
    }
    .ContactModule-container-parent-class .third_tdata {
        padding-left: 30px;
    }
    .ContactModule-container-parent-class .action_table .first_tdata {
        padding: 0 30px 0 40px;
    }
}

@media only screen and (max-width: 1150px) {
    .ContactModule-container-parent-class .imported_table th:first-child {
        width: 370px;
    }
    .ContactModule-container-parent-class .imported_map_details_box {
        width: 240px;
    }
    .ContactModule-container-parent-class .second_tdata {
        padding: 0 0 0 15px;
    }
    .ContactModule-container-parent-class .imported_table table tbody tr:not(:first-child) .second_tdata {
        padding: 0 5px 0 15px;
    }
    .ContactModule-container-parent-class .third_tdata {
        padding-left: 25px;
    }
    .ContactModule-container-parent-class .action_table .first_tdata {
        padding: 0 30px 0 40px;
    }
}

@media only screen and (max-width: 1110px) {
    .ContactModule-container-parent-class .uploaded_file_details p {
        font-size: 14px;
    }
    /* .tabs .tab a {
        font-size: 16px;
    } */
    .ContactModule-container-parent-class .imported_table {
        padding: 0 9px 0 10px;
    }
    .ContactModule-container-parent-class .imported_table thead th h6 {
        font-size: 12px;
    }
    .ContactModule-container-parent-class .imported_table th:first-child {
        width: 262px;
    }
    .ContactModule-container-parent-class .imported_table th:nth-child(2) {
        width: 181px;
    }
    .ContactModule-container-parent-class .imported_table th:last-child {
        width: 162px;
    }
    .ContactModule-container-parent-class .imported_table label .skip_text {
        font-size: 10px;
    }
    .ContactModule-container-parent-class .imported_map_details h6 {
        font-size: 12px;
    }
    .ContactModule-container-parent-class .imported_map_details p {
        font-size: 10px;
    }
    .ContactModule-container-parent-class .second_tdata p {
        font-size: 10px;
        line-height: 18px;
        text-align: left;
    }
    .ContactModule-container-parent-class .imported_map_details {
        margin-left: 10px;
    }
    .ContactModule-container-parent-class .imported_map_details_box {
        width: 189px;
    }
    .ContactModule-container-parent-class .second_tdata {
        padding: 0 0 0 10px;
    }
    .ContactModule-container-parent-class .third_tdata {
        padding-left: 10px;
    }
    .ContactModule-container-parent-class .imported_table table tbody tr:not(:first-child) .second_tdata {
        padding: 0 0 0 10px;
    }
    .ContactModule-container-parent-class .action_table .first_tdata {
        padding: 0 10px 0 20px;
    }
    
    
   body .main__content_wrapper .main__content, body.active__minimal_sidebar .main__content_wrapper .main__content {
    margin-left: 10px;
    width: 100%;
    margin-right: 10px;
}
}

@media only screen and (max-width: 768px) {
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .uploaded_file_details_section {
        padding-left: 31px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .uploaded_file_details {
        margin-right: 20px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .back_next_buttons {
        padding: 13px 20px !important;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .back_next_buttons span {
        line-height: 18px !important;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_map_details_box {
        width: 200px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_table .input-field {
        margin: 0 0 0 5px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_table .select-wrapper input.select-dropdown {
        font-size: 10px;
    }
    .ContactModule-container-parent-class .imported_table .dropdown-content li>span {
        padding: 0 0 0 28px;
        font-size: 14px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_table table tbody tr:first-child .input-field .select-wrapper input.select-dropdown {
        width: 105px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_table table tbody tr:nth-child(2) .input-field .select-wrapper input.select-dropdown {
        width: 58px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_table table tbody tr:nth-child(3) .input-field .select-wrapper input.select-dropdown {
        width: 86px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_table .first_tdata {
        padding: 0 0 0 10px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .imported_table .second_tdata p {
        padding: 0 0 0 10px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .action_table table tbody tr .input-field .select-wrapper input.select-dropdown {
        width: 112px !important;
    }
}

/* ===================== Duplicate Rule css ====================== */

.main_section_title_section {
    padding: 30px 0;
    height: auto;
}

.main_section_title_section h6 {
    margin-bottom: 20px;
    justify-content: center;
}

.main_content_main_section_divider {
    height: 2px;
    background-color: var(--gray_light);
}

.ContactModule-container-parent-class .duplicate_rule_content_section {
    width: 100%;
    height: auto;
}

.ContactModule-container-parent-class .duplicate_rule_content {
    margin: 30px auto;
    margin-left: auto;
    margin-right: auto;
    height: auto;
    /* margin-bottom: 49px; */
    /* max-width: 80%; */
    max-width: 850px;
}

body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .duplicate_rule_content {
    /* margin-left: 29.72%; */
    /* margin-bottom: 49px; */
}

.ContactModule-container-parent-class .duplicate_rule_content_title {
    color: var(--dark_blue) !important;
}

.ContactModule-container-parent-class .duplicate_rule_form .options {
    margin: 20px 0;
}

.ContactModule-container-parent-class .duplicate_rule_form label {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
    color: #ADB7C5;
}

.ContactModule-container-parent-class .duplicate_rule_form label span {
    font-style: normal;
    font-weight: 400;
    font-size: 16px !important;
    line-height: 26px !important;
    color: #ADB7C5 !important;
    padding-left: 30px !important;
}

.ContactModule-container-parent-class .duplicate_rule_form [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after {
    border: 1px solid var(--light_blue);
    border-radius: 5px;
    top: 3px;
}

.ContactModule-container-parent-class .duplicate_rule_form [type="checkbox"].filled-in:checked+span:not(.lever):after {
    border: none;
    background-color: var(--light_blue);
    top: 3px;
    border-radius: 5px;
}

.ContactModule-container-parent-class .duplicate_rule_form [type="checkbox"].filled-in:checked+span:not(.lever):before {
    top: 4px;
    left: 3px;
    width: 6.5px;
    height: 10.5px;
}

.ContactModule-container-parent-class .duplicate_rule_form .duplicate_rule_checked span {
    color: var(--dark_blue) !important;
}

.ContactModule-container-parent-class .content_duel_buttons .back_button {
    color: rgba(19, 49, 89, 0.65) !important;
    background: var(--gray_dark);
    /* margin-right: 35px; */
    margin-right: 15px;
    min-width: 150px;
}

.ContactModule-container-parent-class .report__table_log button:focus {
    background-color: #133159;
    color: #fff;
}

.ContactModule-container-parent-class .fullDetails__title {
    margin-top: 20px !important;
    font-size: 24px !important;
}

.ContactModule-container-parent-class .report__log_table_content p {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #999;
}

.ContactModule-container-parent-class .report__log_table_content p span {
    color: #777;
    width: 200px;
    display: inline-block;
    text-align: right;
}

.ContactModule-container-parent-class .report__log_table_content {
    padding: 20px 0;
}

/* ===================== Media Query Duplicate Rule css ====================== */

/* 
@media only screen and (max-width: 1366px) {
   .duplicate_rule_content {
        margin-left: 15.744%;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .duplicate_rule_content {
        margin-left: 23.81%;
    }  
} */

@media only screen and (max-width: 992px) {
    /* body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .duplicate_rule_content {
        margin-left: 51px;
    } */
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .duplicate_rule_form label span {
        font-size: 14px !important;
    }
}

@media only screen and (max-width: 768px) {
    /* body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .duplicate_rule_content {
        margin-left: 21px;
    } */
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .duplicate_rule_form label span {
        font-size: 12px !important;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .content_duel_buttons .back_button {
        margin-right: 25px;
    }
}

/* ===================== Create Deal css ====================== */

.ContactModule-container-parent-class .new_deal_title {
    padding-top: 30px;
}

.ContactModule-container-parent-class .duplicate_rule_content_section, .create_deal_content_section, .add_source_content_section, .add_tag_content_section {
    /* min-height: 502px; */
    position: relative;
}

/* Toggle Button */

.ContactModule-container-parent-class .create_deal_toggle_button_section, .add_source_content_box {
    padding: 29px 0;
}

.ContactModule-container-parent-class .create_deal_toggle_button_section p {
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 500;
    color: var(--dark_blue);
}

.ContactModule-container-parent-class .switch label .lever {
    width: 70px;
    height: 40px;
    border-radius: 40px;
    margin: 0;
    background: #FFFFFF;
    border: 1px solid rgba(19, 49, 89, 0.65);
}

.ContactModule-container-parent-class .switch label .lever:before {
    background-color: var(--red);
}

.ContactModule-container-parent-class .switch label .lever:before, .switch label .lever:after {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    left: 4px;
    top: 4px;
}

.ContactModule-container-parent-class .switch label .lever:after {
    background-color: var(--red);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ContactModule-container-parent-class .switch label input[type=checkbox]:checked+.lever:before, .switch label input[type=checkbox]:checked+.lever:after {
    left: 35px;
}

.ContactModule-container-parent-class .switch label input[type="checkbox"]:checked+.lever {
    background-color: var(--light_blue);
    border: 1px solid var(--light_blue);
}

.ContactModule-container-parent-class .switch label input[type=checkbox]:checked+.lever:after {
    background-color: #fff;
}

.ContactModule-container-parent-class .switch label input[type=checkbox]:not(:disabled)~.lever:active:before, input[type=checkbox]:not(:disabled).tabbed:focus~.lever::before {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: rgba(0, 0, 0, 0.08);
}

.ContactModule-container-parent-class .switch label input[type=checkbox]:checked:not(:disabled)~.lever:active::before, input[type=checkbox]:checked:not(:disabled).tabbed:focus~.lever::before {
    -webkit-transform: scale(1);
    transform: scale(1);
    background-color: rgba(38, 166, 154, 0.15);
}

/* ============= Main Content Duel Button Css ============== */

.ContactModule-container-parent-class .content_duel_buttons {
    /* position: absolute; */
    /* bottom: 209px; */
    width: 100%;
}

.ContactModule-container-parent-class .text-danger {
    color: #d00;
    font-size: 12px;
}

.ContactModule-container-parent-class .add_tag_content_section .content_duel_buttons {
    position: static;
}

/* Pipeline Box Css */

.ContactModule-container-parent-class .select_pipeline_container {
    display: none;
}

.ContactModule-container-parent-class .select_pipeline_content {
    height: auto;
    width: 444px;
    margin: auto;
}

.ContactModule-container-parent-class .select_numbering_box {
    width: 50px;
    height: 173px;
    flex-direction: column;
}

.ContactModule-container-parent-class .select_pipeline_content_box {
    height: auto;
    padding-left: 14px;
}

.ContactModule-container-parent-class .select_pipeline_content_box p {
    font-size: 16px;
}

.ContactModule-container-parent-class .horizontal_line {
    height: 53px;
    width: 4px;
    background-color: rgba(19, 49, 89, 0.35);
    margin: 10px 0;
}

.ContactModule-container-parent-class .select_numbers {
    height: 50px;
}

.ContactModule-container-parent-class .first_selection {
    /* margin: 10px 0 39px 0; */
    margin: 10px 0;
}

.ContactModule-container-parent-class .first_selection p {
    color: var(--dark_blue);
    line-height: 30px;
    margin-bottom: 10px;
}

.ContactModule-container-parent-class .second_selection p {
    line-height: 22px;
    margin-bottom: 10px;
    color: rgba(19, 49, 89, 0.35);
}

.ContactModule-container-parent-class .selection_done p {
    color: var(--dark_blue);
    line-height: 30px;
    margin-bottom: 10px;
}

#pipeline_stage {
    display: none;
}

/*================================ Select Pipeline Form Css ============================*/

.ContactModule-container-parent-class .select_pipeline_content_box .input-field .select-wrapper input.select-dropdown {
    width: 365px;
}

.ContactModule-container-parent-class .select_pipeline_content_box .input-field {
    margin: 0;
    height: 50px;
}

.ContactModule-container-parent-class .select_pipeline_content_box .select-wrapper svg {
    display: none;
}

.ContactModule-container-parent-class .select_pipeline_content_box .select-wrapper input.select-dropdown {
    border: 2px solid #E5E8EF;
    border-radius: 5px;
    margin: 0;
    padding: 11px 0 11px 11px;
    height: auto;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #546376;
}

.ContactModule-container-parent-class .select_pipeline_content_box .select-wrapper::after {
    position: absolute;
    content: "";
    display: block;
    top: 21px;
    right: 11px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-color: #ADB7C5 transparent transparent transparent;
}

.ContactModule-container-parent-class .select_pipeline_content_box .input-field .select-wrapper ul {
    background: #FFFFFF;
    border: 2px solid #E5E8EF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.ContactModule-container-parent-class .select_pipeline_content_box .dropdown-content li {
    color: #133159;
    min-height: auto;
    line-height: 24px;
    width: 100%;
    text-align: left;
    border-bottom: 0.5px solid #F6F7FB;
}

.ContactModule-container-parent-class .select_pipeline_content_box .select-dropdown.dropdown-content li:hover {
    background: rgba(60, 126, 243, 0.1);
}

.ContactModule-container-parent-class .select_pipeline_content_box .dropdown-content li>span {
    display: block;
    padding: 13px 0 13px 20px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
}

.ContactModule-container-parent-class .select_pipeline_content_box .select-dropdown.dropdown-content li:hover span {
    color: #3C7EF3;
    padding: 8px 0 18px 20px;
}

.ContactModule-container-parent-class .select_pipeline_content_box .dropdown-content li:nth-child(1)>span {
    cursor: not-allowed;
}

/* =============================== Media Query Create Deal Css =================================== */

/* =============================== Add Source Page Css =================================== */

.ContactModule-container-parent-class .add_source_content_box p {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: var(--dark_blue);
}

.ContactModule-container-parent-class .add_source_content_box .input-field {
    margin: 0 0 0 20px;
    height: 50px;
}

.ContactModule-container-parent-class .add_source_content_box .input-field .select-wrapper svg {
    display: none;
}

.ContactModule-container-parent-class .add_source_content_box .select-wrapper::after {
    position: absolute;
    content: '';
    border-top: 0px;
    border-left: 0px;
    display: block;
    width: 9px;
    height: 9px;
    top: 50%;
    right: 9px;
    margin-top: -2px;
    border-bottom: 2px solid #667993;
    border-right: 2px solid #667993;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: 50% 0;
}

.ContactModule-container-parent-class .add_source_content_box .input-field .select-wrapper input.select-dropdown {
    width: 308px;
}

.ContactModule-container-parent-class .add_source_content_box .select-wrapper input.select-dropdown {
    border: 1px solid #E5E8EF;
    border-radius: 5px;
    margin: 0;
    padding: 10px 0 10px 9px;
    height: auto;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 28px;
    color: #546376;
    opacity: 0.8;
}

.ContactModule-container-parent-class .add_source_content_box .dropdown-content li {
    color: rgba(19, 49, 89, 0.35);
    min-height: auto;
    line-height: 30px;
    width: 100%;
    text-align: left;
    border-bottom: 0.5px solid #F6F7FB;
}

.ContactModule-container-parent-class .add_source_content_box .select-dropdown.dropdown-content li:hover {
    background: rgba(60, 126, 243, 0.1);
}

.ContactModule-container-parent-class .add_source_content_box .select-dropdown.dropdown-content li:hover span {
    color: #3C7EF3;
}

.ContactModule-container-parent-class .add_source_content_box .dropdown-content li>span {
    display: block;
    padding: 0 0 0 26px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 50px;
    color: rgba(19, 49, 89, 0.35);
}

/* =============================== Add Source Page Css =================================== */

@media only screen and (max-width: 768px) {
    .ContactModule-container-parent-class .add_source_content_box p {
        font-size: 14px;
    }
}

/* =============================== New Tag Page Css =================================== */

.ContactModule-container-parent-class .new_tag_title {
    padding: 24px 0;
}

.ContactModule-container-parent-class .new_tag_title h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 62px;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color: var(--light_blue);
    margin: 0 0 10px 0;
}

.ContactModule-container-parent-class .new_tag_title h6 {
    margin-bottom: 10px;
}

.ContactModule-container-parent-class .add_tag_content_box p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    color: var(--dark_blue);
    margin: 29px 0 20px 0px;
}

.ContactModule-container-parent-class .add_tag_button a {
    background: var(--gray_dark);
    width: 139px;
    margin: auto;
    padding: 10px 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 30px;
    color: #5D718D;
}

.ContactModule-container-parent-class .add_tag_button a span {
    margin-left: 10px;
}

/* =============================== Media Query New Tag Page Css =================================== */

@media only screen and (max-width: 768px) {
    .ContactModule-container-parent-class .new_tag_title h3 {
        font-size: 32px;
    }
    .ContactModule-container-parent-class .add_tag_button a {
        width: 122px;
        font-size: 12px;
    }
}

/* =============================== Complete Page Css =================================== */

.ContactModule-container-parent-class .complete_celebrate_icon {
    margin: 49px 0 57px 0;
}

.ContactModule-container-parent-class .complete_section_content h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 0.01em;
    color: var(--light_green);
    margin: 0;
}

.ContactModule-container-parent-class .complete_section_content p {
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    margin: 20px 0 50px 0;
}

.ContactModule-container-parent-class .all_contact_button a {
    /* width: 213px; */
    width: 248px;
    margin: auto;
}

.ContactModule-container-parent-class .contact__import_tab button {
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    padding: 6px 15px;
    background: #f8f8f8;
    border-radius: 6px;
    min-width: 180px;
    min-height: unset;
    transition: all 0.4s ease-in;
    margin: 0 4px 0 0;
}

.ContactModule-container-parent-class .contact__import_tab button svg {
    margin-bottom: 0 !important;
}

.ContactModule-container-parent-class .contact__import_tab .MuiTabs-scroller {
    margin-left: 20px;
}

.ContactModule-container-parent-class .contact__import_tab button.Mui-selected {
    color: var(--accent-text-color);
    background: var(--accent-color) !important;
}

.ContactModule-container-parent-class .contact__import_tab button svg.cimport-warning-icon {
    fill: #F2994A;
}

.ContactModule-container-parent-class .contact__import_tab button.Mui-selected svg {
    fill: var(--accent-text-color);
}

.ContactModule-container-parent-class .contact__import_tab .MuiTabs-indicator {
    display: none;
}

/* =============================== Media Query Complete Page Css =================================== */

@media only screen and (max-width: 768px) {
    .ContactModule-container-parent-class .complete_celebrate_icon {
        margin-bottom: 62px;
    }
    .ContactModule-container-parent-class .complete_section_content h4 {
        font-size: 32px;
        line-height: 48px;
    }
    .ContactModule-container-parent-class .complete_section_content p {
        font-size: 16px;
        line-height: 24px;
        margin: 29px 0 53px 0;
    }
    .ContactModule-container-parent-class .all_contact_button a {
        width: 178px;
    }
}

/* =============================== Import File List Page Css =================================== */

.main_content_container_import_list {
    min-height: 914px;
    margin-bottom: 0;
}

.main_content_container_import_list .main_content_inner_container {
    /* min-height: 914px; */
}

.ContactModule-container-parent-class .import_file_list_container {
    padding: 0 20px;
}

.ContactModule-container-parent-class .import_file_list_section {
    min-height: unset;
    max-width: 100%;
    width: 100%;
    margin: -40px auto 0;
    background: var(--gray_light);
    border-radius: 10px;
    padding: 0 22px 0 18px;
}

body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_file_list_section {
    max-width: 100%;
    padding: 0 20px 0 20px;
}

/* ========Table CSS========== */

.ContactModule-container-parent-class .import_list_table {}

.ContactModule-container-parent-class .import_list_table table {
    border-collapse: separate;
    border-spacing: 0 20px;
}

.ContactModule-container-parent-class .import_list_table table tr, .import_list_table table tr {
    padding: 0;
}

.ContactModule-container-parent-class .import_list_table table tr {
    border-bottom: none;
}

/* Color CSS for table */

.ContactModule-container-parent-class .color_blue {
    color: var(--light_blue) !important;
}

.ContactModule-container-parent-class .color_green {
    color: var(--green2) !important;
}

.ContactModule-container-parent-class .color_dark_blue {
    color: var(--dark_blue) !important;
}

.ContactModule-container-parent-class .color_purple {
    color: var(--purple) !important;
}

.ContactModule-container-parent-class .color_red {
    color: var(--red) !important;
}

.ContactModule-container-parent-class .color_orange {
    color: var(--orange) !important;
}

.ContactModule-container-parent-class .table_head_slash {
    color: #ACB7C5;
}

/* Table Head */

.ContactModule-container-parent-class .import_list_table table thead {
    background: #FFFFFF;
}

.ContactModule-container-parent-class .import_list_table table thead tr th:first-child {
    border-radius: 10px 0 0 10px;
}

.ContactModule-container-parent-class .import_list_table table thead tr th:last-child {
    border-radius: 0 10px 10px 0;
    width: 330px;
}

.ContactModule-container-parent-class .import_list_table table thead th {
    color: var(--dark_blue);
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    padding: 8px 0 14px 0;
    letter-spacing: 0.01em;
}

.ContactModule-container-parent-class .import_list_table table thead th p {
    font-size: 14px;
    line-height: 24px;
    color: #667993;
}

/* Table Head Div CSS */

.ContactModule-container-parent-class .import_list_table table thead div {
    height: 51px;
}

.ContactModule-container-parent-class .import_list_table table thead div {
    border-right: 3px solid var(--gray_light);
}

.ContactModule-container-parent-class .import_list_table table thead .table_head_1 {
    padding-left: 20px;
}

.ContactModule-container-parent-class .import_list_table table thead .table_head_2 {}

.ContactModule-container-parent-class .import_list_table table thead .table_head_3 {}

.ContactModule-container-parent-class .import_list_table table thead .table_head_4 {}

.ContactModule-container-parent-class .import_list_table table thead .table_head_5 {
    border-right: none;
}

/* Table Body CSS */

.ContactModule-container-parent-class .import_list_table table tbody {}

.ContactModule-container-parent-class .import_list_table table tbody tr {
    height: 80px;
    background-color: #FFFFFF;
}

/* Table Body Data CSS */

.ContactModule-container-parent-class .import_list_table table tbody tr td {
    padding: 0;
}

.ContactModule-container-parent-class .import_list_table table tbody tr td:first-child {
    border-radius: 5px 0 0 5px;
}

.ContactModule-container-parent-class .import_list_table table tbody tr td:last-child {
    border-radius: 0 5px 5px 0;
}

.ContactModule-container-parent-class .table_data_1 div {
    text-align: left;
    padding-left: 20px;
}

.ContactModule-container-parent-class .table_data_1 p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    width: 200px;
}

.ContactModule-container-parent-class .table_data_1 span {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 24px;
    color: var(--gray);
}

.ContactModule-container-parent-class .table_data_row_5 .table_data_2 div {
    margin-left: -17px;
}

.ContactModule-container-parent-class .table_data_2 span {
    margin-left: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.ContactModule-container-parent-class .table_data_3 div div {
    /* width: 135px; */
    text-align: left;
}

.ContactModule-container-parent-class .table_data_3 span {
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 24px;
}

body .table_data_3 span {
    font-size: 20px;
    line-height: 20px;
}

.ContactModule-container-parent-class .outline_button {
    background: #FFFFFF;
    border: 1px solid rgba(19, 49, 89, 0.35);
    border-radius: 5px;
    padding: 6px 10px;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.35);
}

.ContactModule-container-parent-class .table_data_3 .view_button {
    padding: 6px 13px 6px 14px;
}

.outline_button:focus {
    background: #FFFFFF;
}

.ContactModule-container-parent-class .table_data_4 div h6 {
    font-style: normal;
    font-weight: 500;
    /* font-size: 20px; */
    /* line-height: 22px; */
    letter-spacing: 0.01em;
    font-size: 16px;
    line-height: 20px;
}

.ContactModule-container-parent-class .table_data_4 div p {
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 22px;
    letter-spacing: 0.01em;
}

.ContactModule-container-parent-class .table_data_4 div .color_dark_blue h6, .table_data_4 div .color_dark_blue p {
    color: var(--dark_blue);
}

.ContactModule-container-parent-class .table_data_4 div .color_purple h6, .table_data_4 div .color_purple p {
    color: var(--purple);
}

.ContactModule-container-parent-class .table_data_4 div .color_red h6, .table_data_4 div .color_red p {
    color: var(--red);
}

/* .table_data_5 .table_action_buttons {
    margin-right: 20px;
} */

body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .table_data_5 .table_action_buttons {
    margin-right: 10px;
}

.ContactModule-container-parent-class .table_data_5 .table_action_buttons button {
    padding: 9px 10px;
    background-color: var(--light_blue);
    border-radius: 5px;
    outline: none;
    border: none;
}

.ContactModule-container-parent-class .table_data_5 .table_action_buttons button div {
    height: 20px;
}

.ContactModule-container-parent-class .table_data_5 .table_action_buttons button p {
    color: #FFFFFF;
    font-weight: 500;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
}

.ContactModule-container-parent-class .table_data_5 .table_action_buttons button p {
    margin-left: 10px;
}

.ContactModule-container-parent-class .table_data_5 .table_action_buttons button:first-child {
    margin-right: 15px;
}

.ContactModule-container-parent-class .table_data_5 .table_action_buttons button:last-child {
    background-color: var(--red);
}

/* Pagination CSS */

.ContactModule-container-parent-class .pagination_content .arrow {
    width: 50px;
    height: 50px;
    background: #3C7EF3;
    border-radius: 5px;
    border: none;
    outline: none;
    margin-right: 15px;
}

.ContactModule-container-parent-class .pagination_content .current_number span {
    width: 65px;
    height: 50px;
    background: #FFFFFF;
    border: 2px solid #3C7EF3;
    box-sizing: border-box;
    border-radius: 5px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;
    color: #3C7EF3;
}

.ContactModule-container-parent-class .pagination_content .pagination_slash {
    margin: 0 15px;
    width: 40px;
    height: 2px;
    background-color: #3C7EF3;
    transform: rotate(118.07deg);
}

.ContactModule-container-parent-class .pagination_content .total_number {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    text-transform: uppercase;
    color: #3C7EF3;
}

.ContactModule-container-parent-class .pagination_content .right_arrow {
    margin: 0 0 0 16px;
}

.ContactModule-container-parent-class .process__mode_chose [type="radio"]:not(:checked)+span, [type="radio"]:checked+span {
    padding-left: 28px;
}

.ContactModule-container-parent-class .addToFile__li span {
    font-family: 'Poppins';
}

.ContactModule-container-parent-class .addToFile__li small {
    margin-left: 30px;
    font-size: 13px;
    margin-top: -6px;
}

.ContactModule-container-parent-class .process__mode_chose {
    column-gap: 20px;
}

.ContactModule-container-parent-class .process__mode_chose label {
    cursor: pointer;
}

.ContactModule-container-parent-class .process__drip_mode .global__form_control.h45px {
    height: 45px;
}

.ContactModule-container-parent-class .process__drip_mode .global__form_control {
    font-weight: normal;
    font-size: 14px !important;
    line-height: 22px;
    letter-spacing: 0.01em;
    color: #8896A8;
    padding-left: 12px !important;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    background: #FFFFFF !important;
    margin: 0px !important;
    position: unset !important;
    z-index: 0 !important;
}

.ContactModule-container-parent-class .process__drip_mode .global__form_control:focus {
    box-shadow: unset !important;
}

.ContactModule-container-parent-class .process__drip_mode .MuiInput-underline:after, .process__drip_mode .MuiInput-underline:before {
    display: none;
}

.ContactModule-container-parent-class .import_content_container .full__details p {
    font-size: 18px;
    color: #777;
}

.ContactModule-container-parent-class .import-files .full__details_title {
    font-size: 20px;
    margin: 15px 0 15px;
    font-weight: 600;
}

.ContactModule-container-parent-class .import_content_container .full__details p span {
    font-weight: 600;
    color: #000000b3;
    width: 180px;
    display: inline-block;
    text-align: right;
    padding-right: 15px;
}

/* Table Loader */

.ContactModule-container-parent-class .dataTable__wrapper {
    position: relative;
}

.ContactModule-container-parent-class .dataTable__wrapper .new-loader {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 9;
    margin-top: 10px;
}

.ContactModule-container-parent-class .new-loader .MuiButton-root {
    background: transparent !important;
    padding: 0;
    margin-top: 7px;
    text-align: center;
    width: 100%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    margin-left: 20px;
    color: #000 !important;
}

.ContactModule-container-parent-class .new-loader .MuiCircularProgress-root {
    width: 60px !important;
    height: 60px !important;
}

/* =============================== Media Query Import File List Page Css =================================== */

@media only screen and (max-width: 1366px) {
    .ContactModule-container-parent-class .import_file_list_container {
        padding: 0 10px;
    }
    .ContactModule-container-parent-class .import_file_list_section {
        padding: 0px 10px 0 10px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_file_list_container {
        padding: 0 11px 0 12px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_file_list_section {
        padding: 0 12px 0 13px;
    }
    .ContactModule-container-parent-class .import_list_table table thead th p {
        font-size: 12px;
        line-height: 16px;
    }
    .ContactModule-container-parent-class .import_list_table table thead tr th:last-child {
        width: 104px;
    }
    .ContactModule-container-parent-class .table_data_1 p {
        font-size: 14px;
    }
    .ContactModule-container-parent-class .table_data_1 span {
        font-size: 10px;
    }
    .ContactModule-container-parent-class .table_data_2 span {
        font-size: 14px;
    }
    /* .table_data_3 div div {
        width: 87px;
    } */
    .ContactModule-container-parent-class .table_data_3 span {
        font-size: 20px;
    }
    .ContactModule-container-parent-class .outline_button {
        font-size: 12px;
    }
    .ContactModule-container-parent-class .table_data_3 .view_button {
        padding: 6px 22px 6px 14px;
    }
    .ContactModule-container-parent-class .table_data_5 .table_action_buttons {
        margin-right: 15px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .table_data_5 .table_action_buttons {
        margin-right: 49px;
    }
    .ContactModule-container-parent-class .table_data_5 .table_action_buttons button {
        padding: 0;
        background-color: #fff;
        outline: none;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid var(--light_blue);
    }
    .ContactModule-container-parent-class .table_data_5 .table_action_buttons button div {
        display: none;
    }
    .ContactModule-container-parent-class .table_data_5 .table_action_buttons button p {
        display: none;
    }
    .ContactModule-container-parent-class .table_data_5 .table_action_buttons button:first-child {
        margin-right: 10px;
        background-image: url("./import-contact-image/1.png");
        background-repeat: no-repeat;
        background-position: center;
    }
    .ContactModule-container-parent-class .table_data_5 .table_action_buttons button:last-child {
        background-color: #fff;
        background-image: url("./import-contact-image/2.png");
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid rgba(255, 88, 88, 0.6);
        ;
        box-shadow: 0px 4px 4px rgba(255, 38, 74, 0.15);
    }
    
    .ContactModule-container-parent-class .table_data_1 div { 
    padding-left: 10px;
}

}

@media only screen and (max-width: 992px) {
    .main_content_container_import_list {
        height: 914px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main_content_container_import_list .import_file_list_back_button {
        padding: 13px 20px;
        line-height: 18px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main_content_container_import_list {
        height: 914px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_file_list_container {
        padding: 0 10px 0 10px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_file_list_section {
        padding: 0 10px 0 10px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_list_table table thead th {
        font-size: 14px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_list_table table thead th p {
        font-size: 8px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .import_list_table table thead tr th:last-child {
        width: 55px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .table_data_2 svg {
        width: 16px;
        height: 16px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .table_data_2 span {
        font-size: 12px;
    }
    /* body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .table_data_3 div div {
        width: 61px;
    } */
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .table_data_3 span {
        font-size: 14px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .outline_button {
        font-size: 10px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .table_data_4 div h6 {
        font-size: 16px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .table_data_4 div p {
        font-size: 8px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .outline_button {
        padding: 4px 8px;
        font-size: 10px;
    }
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .table_data_5 .table_action_buttons {
        margin-right: -2px;
        transform: scale(0.66);
    }
}

@media only screen and (max-width: 768px) {
    body.has-fixed-sidenav.active__minimal_sidebar .main__content_wrapper .main__content .pagination_content {
        width: 194px;
        height: 50px;
        background: #F6F7FB;
        margin: auto;
    }
    .ContactModule-container-parent-class .pagination_content .arrow {
        width: 30px;
        height: 30px;
        margin-right: 11px;
        margin-left: 10px;
    }
    .ContactModule-container-parent-class .pagination_content .current_number span {
        display: block;
        width: 40px;
        height: 30px;
        text-align: center;
    }
    .ContactModule-container-parent-class .pagination_content .pagination_slash {
        margin: 0 4px;
        width: 24px;
    }
    .ContactModule-container-parent-class .pagination_content .total_number {
        font-size: 12px;
    }
    .ContactModule-container-parent-class .pagination_content .right_arrow {
        margin: 0 10px 0 11px;
    }
}


/* Step form update */

.ContactModule-container-parent-class .process_number_box {
    padding: 0;
}

.ContactModule-container-parent-class .process_number_box .number_box {
    width: 5.666%;
    position: relative;
    margin: 0 6px;
}

.ContactModule-container-parent-class .number_checked .done_step {
    margin: auto;
}

.ContactModule-container-parent-class .process_number_box hr {
    width: 100%;
}

.ContactModule-container-parent-class .number_name1 {
    width: auto;
}

.ContactModule-container-parent-class .import_content_container span {
    font-size: 14px;
}

.ContactModule-container-parent-class .number {
    margin: auto;
}

.ContactModule-container-parent-class .number_name {
    width: auto;
}

.ContactModule-container-parent-class .last-item.number_name6 {
    text-align: center;
    width: auto;
}


.ContactModule-container-parent-class .process_number_box .number_box .number_name,
.ContactModule-container-parent-class .process_number_box .number_box .number_name1,
.ContactModule-container-parent-class .process_number_box .number_box .number_name6 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 200px;
    bottom: -32px;
}

.process__drip_mode .time__setting_wrapper .timepicker-wrapper {
    display: flex;
}


@media(min-width: 1440px) {
    .ContactModule-container-parent-class .process_number_section {
        width: 1000px;
    }
}