.global__dropdown .MuiList-padding {
  padding: 0 !important;
}
.global__dropdown .MuiPopover-paper {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1) !important;
  border-radius: 5px;
  width: auto !important;
  /* top: 150px !important; */
}

.global__dropdown .MuiListItemIcon-root {
  min-width: unset !important;
}

.global__dropdown .MuiListItemText-root.MuiListItemText-inset {
  padding-left: 10px;
}

.global__dropdown .MuiListItemIcon-root span {
  height: 20px;
}
.global__dropdown .MuiListItemIcon-root span svg{
  width: 20;
  height: 20px;
}

.global__dropdown .MuiMenuItem-root {
  display: flex !important;
  align-items: center !important;
}