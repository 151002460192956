.ContactModule-container-parent-class .image_preview_component_show{
    display: block;
    width: 200px;
    height: 200px;
}
.ContactModule-container-parent-class .image_preview_component_hide{
    display: none !important;
}
.ContactModule-container-parent-class .image_preview_component_hide_hints{
    font-size: 12px;
    color: #657a98;
}
.ContactModule-container-parent-class .image_preview_component_hide_hints a{
    color: #039be5;
    cursor: pointer;
    font-size: 14px;
}