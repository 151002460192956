.paginationWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 30px 13px;
    width: 100%;
}

.paginationInnerWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
}

.paginationPrev,
.paginationInput,
.paginationNext
{
    width: 40px !important;
    height: 40px !important;
    display: inline-flex;
    border: 1px solid rgba(98,88,255,0.5) !important;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;
    padding: 8px !important;
    margin: 0 !important;
}

.paginationTotalPage {
    width: 40px !important;
    height: 40px !important;
    display: inline-flex;
    border: 1px solid rgba(148,157,178,.24) !important;
    align-items: center;
    justify-content: center;
    border-radius: 4px !important;
    padding: 8px !important;
    margin: 0 !important;
}

.paginationDivider {
    position: relative;
    margin: 0 10px;
    color: rgba(148,157,178,.24);
}

.paginationDivider:after {
    position: absolute;
    content: "";
    top: -14px;
    left: 0;
    width: 1px;
    background: #ddd;
    height: 30px;
    transform: rotate(25deg);
}

.disabled {
    opacity: .5;
    cursor: not-allowed!important;
}