.fileTrayWrapper {
    padding: 15px;
}

.fileTray {
    width: 100%;
    height: 250px;
    background-color: #635dff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.uploadInstruction {
    color: white;
    font-size: 20px;
}

.uploadRule {
    color: white;
    font-size: 15px;
}

.uploadFileHide {
    display: none;
}