.auto__dialer_wrapper-card {
    border-radius: 10px;
    box-shadow: none;
}
.auto__dialer_wrapper_content {
    padding: 10px 10px 0;
}
.auto__dialer_top_section {
    border-bottom: 1px solid #e5e8ef;
    padding-bottom: 10px;
    color: #133159;
}

.auto__dialer_category_section > ul {
    margin: 0;
    padding: 0;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.auto__dialer_category_section .auto__dialer_title_wrapper {
    font-size: 18px;
    font-weight: 500;
}

.auto__dialer_category_section .total-lists-number {
    font-size: 12px;
    margin-left: 15px;
}
.auto__dialer_utility_buttons ul {
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.auto__dialer_utility_buttons ul li .rounded-icons {
    width: 35px;
    height: 35px;
    line-height: 35px;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #546376;
    border-radius: 50%;
}

.dialer_list_view_container .option__icons .MuiSvgIcon-root {
    color: #6a6a6a;
    border-radius: 50%;
    border: 1px solid #6a6a6a;
    padding: 2px;
    height: 25px;
    width: 25px;
    margin: 0 2px;
}