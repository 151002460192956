.custom-switch .react-switch-bg {
  display: flex;
}
.custom-switch-off-icon{
  padding: 0px 6px 0 0;
}
.custom-switch-on-icon{
  padding: 4px;
}
.custom-switch-active-mode .react-switch-handle {
  top: 4px !important;
}
.custom-switch-inactive-mode .react-switch-bg {
  border: 1px solid #e1e5ec !important;
  background-color: #e1e5ec !important;
}
.custom-switch-inactive-mode .react-switch-handle{
  top: 4px !important;
  left: 4px
}

.custom-switch .react-switch-handle {
  height: 16px !important;
  width: 16px !important;
}