.modal-assign-campaign .contact__assign__modal__content_vv2{
    display: flex;
    grid-gap: 16px;
}
.modal-assign-campaign .contact__assign__modal__content_vv2 .contact__assign__modal__content_vv2__single{
    flex: 1;
}
.modal-assign-campaign .contact__assign__modal__content_vv2 .contact__assign__modal__content_vv2__single .form-control{
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
}
.modal-assign-campaign .assign_user__time__zone__wr{
    margin-bottom: 8px;
}
.modal-assign-campaign .success__radio__assign__campaign{
    display: inline-flex !important;
    align-items: center !important;
    cursor: pointer;
}

.modal-assign-campaign .contact__assign__modal__get__day__wr button.MuiButton-root{
    display: inline-flex !important;
}