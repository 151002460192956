input, button, select{
    font-family: var(--poppins);
}
.dripbatch_page_wrapper {
    background: #fff;
    border-radius: 10px;
}
.tagBox{
    display: flex;
    border-bottom:1px solid rgba(60, 126, 243, 0.1);
    padding-bottom:10px;
    align-items: center;
}
.add_new_user{
    margin-left: auto;
    margin-top: 8px;
    margin-right: 20px;
}
.add_new_user a{
    background: var(--light_blue);
    padding: 13px 21px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--white);
    border-radius: 5px;
}
.add_new_user a span{
    display: flex;
    margin-right: 10px;
}
.tag_list h5{
    font-weight: normal;
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0.01em;
    color: var(--dark_blue);
    padding: 0px 20px;
}
.tag_list h5 span{
    color: #546376;
}
.tagBox .tag_btn .creat_tag {
    border-radius: 5px;
    padding: 13px 20px;
    background: #3C7EF3;
    color: #fff;
    font-size: 16px;
    border:none;
    display: flex;
}
.tagBox .tag_btn .creat_tag svg {
    background: #fff;
    padding:5px;
    color:#fff;
    border-radius: 50%;
    margin-right: 10px;
}
.details {
    padding: 13px 20px;
}
.details .items{
    display: flex;
    justify-content: space-between;
    padding-bottom: 3px;
    align-items: center;
}
.records {
    display: flex;
    align-items: center;
}
.records span{
    font-size: 16px;
    line-height: 30px;
    color: #133159;
}
.details .items .input-field{
    margin-bottom: unset;
    margin-top: unset;
}
.records input{
    width: 66px !important;
    font-weight: 600;
    margin-left: 10px !important;
    margin-right: 10px !important;
    margin-top: 5px !important;
    background: #FFFFFF;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    position: relative !important;
    font-size: 16px !important;
    line-height: 24px !important;
    letter-spacing: 0.01em;
    color: #546376;
    padding: 0 10px !important;
    height: 37px !important;
    width: 56px !important;
}
.records .select_icon{
    position: absolute;
    top: 15px;
    right: 22px;
    cursor: pointer;
}
.records .caret{
    fill: var(--gray) !important;
    right: 16px !important;
}
.records select:focus{
    outline: unset !important;
}
.details .items .records p {
    font-size: 16px;
    line-height: 30px;
    color: #133159;
}
.record_search{
    position: relative;
}
.record_search .search{
    background: #FFFFFF;
    border: 1px solid rgba(19, 49, 89, 0.65) !important;
    border-radius: 5px !important;
    width: 246px !important;
    height: 40px !important;
    box-sizing: border-box !important;
    padding: 0 30px 0 10px !important;
    font-size: 16px !important;
    line-height: 22px !important;
    letter-spacing: 0.01em  !important;
    color: rgba(84, 99, 118, 0.65) !important;
}
.record_search .search_icon {
    position: absolute;
    right: 30px;
    top: 10px;
    cursor: pointer;
}
.details .items .record_search .search_icon svg {
    position: absolute;
    top: 2px;
}
.details .items .record_search input:focus {
    border:1px solid #333;
    outline: none;
    box-shadow: none;
}
.details table {
    width: 100%;
    height: auto;
    border-collapse: collapse;
    position: relative;
    margin-top: 10px;
    border-radius: 10px;
    table-layout: fixed;
}
.details table thead{
    width: 100%;
    position: relative;
}
/*  */
.details table thead tr th:first-child{
    border-radius: 5px 0 0 5px;
    width: 22.2%;
    box-sizing: border-box;
    white-space: nowrap;
}
.details table thead tr th:nth-child(2){
    width: 24%;
} 
.details table thead tr th:nth-child(2)::after{
    display: none;
}
.details table thead tr th:nth-child(3){
    width: 21.5%;
}
.details table thead tr th:nth-child(4){
    width: 22.5%;
}
.details table thead tr th:last-child{
    border-right: unset;
    border-radius: 0 5px 5px 0;
    width: 15%;
}
.details table thead tr th:last-child::after{
    display: none;
}
.details table tr {
    height: 60px;
    border-bottom: 3px solid rgba(60, 126, 243, 0.05) !important;
    background: white;
}
.details table .header{
    height: 60px;
}
.details table .header th{
    position: relative;
    padding: 0px 30px;
    font-size: 16px;
    line-height: 30px;
    background: #F6F7FB;
    border-right: 2px solid #FFFFFF;
    color: var(--dark_blue);
    font-weight: normal;
}
.details table .header th::after{
    content: '';
    /* background: url(../images/team/filter_icon.svg) no-repeat; */
    position: absolute;
    height: 20px;
    width: 20px;
    right: 0;
    top: 18px;
    cursor: pointer;
}
.details table .header th span{
    
}
.details table tbody tr:last-child {
    border-bottom:none;
}
.details table tr td {
    color: #133159;
    font-size: 13px;
    font-weight: 600;
}
.details table tr td:nth-child(2){
    width: 12%;
}
.details table tr td:last-child{
    width: 20%;
}
.details table tr td:last-child a{
    margin-right: 15px;
}
.details table tr td:last-child a:last-child{
    margin-right: 0px;
}
/* #main__content .details table tbody tr:hover {
    background: #03a9f4;
    color:#fff;
} */
.details table tr td {
    padding: 0px 30px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
}
.details table tr td button.parsed,.details table tr td button.notParsed  {
    width: 120px;
    padding: 8px 30px;
    background: transparent;
    border:1px solid #219653;
    outline:none;
    border-radius: 50px;
    display: flex;
    position: relative;
}
.details table tr td button.notParsed {
    border:1px solid #F2994A;
}
.details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left:15px;
    top:14px;
}
.details table tr td button.parsed .dot_c_blue {
    background: #219653;
   
}
.details table tr td button.notParsed .dot_c_orng {
    background: #F2994A;
}
.details table tr td button.notParsed .txt_c_orng {
    color:#F2994A;
}
.details table tr td button.parsed span {
    color:#219653;
}
.details table tr td button.details_btn, 
.details table tr td button.delete_btn {
    padding: 7px 12px;
    outline:none;
    border-radius: 5px;
    position: relative;
    border:none;
    color:#fff;
    cursor: pointer;
    margin-right: 13px;
}
.details table tr td button.details_btn {
    background: #3C7EF3;
   
}
.details table tr td button.delete_btn {
    background: #FF264A;
   
}
.details table tr td button.details_btn .icons , 
.details table tr td button.delete_btn .icons {
    display: flex;
    justify-content: center;
}

.details table tr td button.details_btn .icons .svg_box ,
.details table tr td button.delete_btn .icons .svg_box  {
    background: #fff;
    padding: 8px ;
    border-radius: 50%;
    width: 17px;
    height: 17px;
    display: flex;
    margin-right: 6px;
    position: relative;
    
}
.details table tr td button.details_btn .icons .svg_box .svg_icon {
    position: absolute;
    top:-2px;
    left:3px;
}
.details table tr td button.details_btn .icons .icon_txt span,
.details table tr td button.delete_btn .icons .icon_txt span  {
    font-weight: 600;
}
.details table tr td button.delete_btn .icons .svg_box .svg_icon {
    position: absolute;
    top:0;
    left:0;
}
.details table tr td button.delete_btn .icons .svg_box .svg_icon svg:nth-of-type(1) {
    position: absolute;
    top:5px;
    left:5px;
}
.details table tr td button.delete_btn .icons .svg_box .svg_icon svg:nth-of-type(2) {
    position: absolute;
    top:7px;
    left:5px;
}
/* #main__content .details table tr td button.details_btn .svg_icon svg {
    position: absolute;
    left:3px;
    top:3px;
} */
.pagination_bar {
    padding: 62px 0 20px;
}
.pagination_bar ul  {
   display: flex;
   align-items: center;
   justify-content: center;
}
.pagination_bar ul li a {
    color:#fff;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 6px 13px;
    margin-left:10px;
    display: block;
    border:2px solid #3C7EF3;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.paginations_text{
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
}
.pagination_bar ul li:nth-child(2) a {
    background: transparent;
    border:2px solid #3C7EF3;
    color:#3C7EF3;
}
.pagination_bar ul li:nth-child(3) a {
    background: transparent;
    color:#3C7EF3;
    border: unset;
    padding: 0px 6px;
}
.pagination_bar ul li:nth-child(3) a span {
    margin-right: 5px;
}
.icon_text{
    gap: 10px;
    font-size: 16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
    font-weight: normal;
}

/* ==========Modal css========== */
.modal_details{
    background: var(--white);
    border-radius: 10px 0px 0px 10px;
    max-width: 832px;
    left: unset;
    top: 80px !important;
    max-height: 88% !important;
}
.top_content {
    border-bottom: 1px solid #E7EFF0;
}
.cross_button {
    background: var(--red);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    cursor: pointer;
}
.sub_title h2 {
    font-size: 24px;
    line-height: 30px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 0.03em;
    color: var(--dark_blue);
    margin: 0;
}

.table_btn{
    background: var(--light_blue);
    padding: 4px 20px 6px 20px;
    border-radius: 3px;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-transform: capitalize;
    cursor: pointer;
}
.details_btn{
    margin-left: 10px;
    margin-right: 15px;
}
.delete_btn{
    background: var(--red);
}
.table_btn span{
    height: 20px;
    width: 20px;
    background: #fff;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    line-height: 19px;
    margin-left: -10px;
    margin-right: 5px;
}
.custom_input label{
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    color: #133159;
} 

/* ======Modal css======= */
.leadsrc_input_fileds{
  padding: 30px;
}
.modal__buttons{
    padding: 5px 0px 20px;
}
.add__group__btn, 
.modal__close__btn {
    font-family: var(--poppins);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 30px;
    height: 30px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: var(--white);
    display: flex;
    align-items: center;
    padding: 25px 17px;
    border-radius: 10px;
    background-color: var(--blue-1);
    border: none;
    cursor: pointer;
}
.modal__close__btn {
    background-color: var(--red);
    margin-left: 20px;
}

/* =============Edit Button css============ */
.edit_button a{
    padding: 8px 9px;
    background: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #133159;
    border: 1px solid #E5E8EF;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: pointer;
}
.button_icon{
    margin-right: 10px;
}
.date_time .date{
    font-size: 16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
}
.date_time .time{
    font-size: 16px;
    line-height: 24px;
    color: rgba(19, 49, 89, 0.65);
    margin-left: 10px;
}
.system_button button{
    background: #F6F7FB;
    border-radius: 5px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: capitalize;
    color: rgba(19, 49, 89, 0.65);
    border: unset;
    cursor: pointer;
    padding: 10px 15px;
}

/* ========Batches Report Table Layout Css=========== */
.drip_batch_field{
    width: 100%;
}
.drip_batch_field label.MuiFormLabel-root{
    font-weight: 500 !important;
    font-size: 16px !important; 
    line-height: 24px !important;
    color: #133159 !important;
}
.drip_batch_field input{
    background: #FFFFFF !important;
    border: 1px solid #E5E8EF !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    line-height: 24px !important;
    color: rgba(19, 49, 89, 0.35) !important;
    padding: 10px 12px !important;
}
.drip_batch_field input:focus{
    box-shadow: unset !important;
}
li ::marker{
    display: none;
}
.second__step__area__select .MuiSelect-select{
    width: unset !important;
}

/* ==============Responsive css=================== */
@media only screen and (min-width: 1666px) and (max-width: 1800px){
    .details table thead tr th:nth-child(4){
        width: 14.5%;
    }
    .details table thead tr th:first-child{
        width: 16.8%;
    }
}
@media only screen and (min-width: 1505px) and (max-width: 1666px){
    .details table thead tr th:first-child{
        max-width: 11%;
    }
    .details table thead tr th:nth-child(3){
        width: 9.5%;
    }
    .details table thead tr th:nth-child(2){
        max-width: 14%;
    }
    .details table thead tr th:nth-child(5){
        max-width: 9%;
    }
    .details table tr td{
        font-size: 15px;
    }
    .details_btn{
        margin-right: 9px;
    }
    .table_btn{
        padding: 4px 13px 6px 13px;
    } 
}
@media only screen and (min-width: 1400px) and (max-width: 1505px){
    .details_btn{
        margin-left: 3px;
        margin-right: 5px;
    }
    .table_btn span{
        margin-left: -7px;
        margin-right: 1px;
    }
    .table_btn{
        padding: 4px 4px 6px 13px;
    }
    .details table tr td{
        font-size: 14px;
    }
    .details table thead tr th:first-child{
        width: 11.2%;
    }
    .details table thead tr th:nth-child(2){
        width: 15%;
    }
    .details table thead tr th:nth-child(3){
        max-width: 13%;
    }
    .details table thead tr th:nth-child(4){
        width: 14.5%;
    }
    .details table thead tr th:last-child{
        width: 11%;
    }
}
@media only screen and (min-width: 1366px) and (max-width: 1400px){
    .details_btn{
        margin-left: 3px;
        margin-right: 5px;
    }
    .table_btn span{
        margin-left: 3px;
        margin-right: 1px;
    }
    .table_btn{
        padding: 4px 4px 6px 13px;
    }
    .details table thead tr th:first-child{
        width: 10.2%;
    }
    .details table thead tr th:nth-child(2){
        width: 13%;
    }
    .details table tr td{
        font-size: 14px;
    }
    .details table thead tr th:nth-child(4){
        width: 12.5%;
    }
    .details table thead tr th:nth-child(3){
        max-width: 10%;
    }
    .details table thead tr th:last-child{
        width: 10%;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1366px){
    .table_btn{
        background: unset;
        padding: 0 !important;
        font-size: 0px;
    }
    .table_btn span{
        border: 1px solid #2F80ED; 
        line-height: 28px;
    }
    .delete_btn span{
        border: 1px solid red;
    }
    .details table tr td{
        font-size: 16px;
    }
    .details table thead tr th:nth-child(3){
        max-width: 9%;
    }
    .details table thead tr th:nth-child(5){
        max-width: 20%;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        top: 11px;
    }
    .pagination_bar{
        padding: 0px 0 6px;
    }
    .details table thead tr th:nth-child(4){
        width: 7%;
    }
    .details table thead tr th:last-child{
        width: 9%;
    }
    .details table tr td button.parsed, .details table tr td button.notParsed{
        width: 100px;
    }
    .details table thead tr th:first-child{
        width: 8%;
    }
    .details table thead tr th:nth-child(2){
        width: 12%;
    }
    .details table .header th{
        font-weight: normal;
        padding: 0px 20px;
    }
    .details table tr td{
        padding: 0px 20px;
    }
    
}
@media only screen and (min-width: 992px) and (max-width: 1024px){
    .details table .header th{
        font-size: 12px !important;
    }
    .pagination_bar{
        padding: 0px 0 6px;
    }
    .details{
        padding: 13px 10px;
    }
    .tag_list h5{
        padding: 0px 10px;
    }
    .table_btn{
        background: unset;
        padding: 0 !important;
        font-size: 0px;
    }
    .table_btn span{
        border: 1px solid #2F80ED; 
        line-height: 28px;
        margin-left: -6px;
        margin-right: -1px;
    }
    .details table .header th{
        padding: 0px 15px;
    }
    .delete_btn span{
        border: 1px solid red;
    }
    .details table tr td{
        font-size: 10px;
    }
    .details table tr td{
        padding: 0px 15px;
    }
    .details table thead tr th:first-child{
        max-width: 11.8%;
    }
    .details table thead tr th:nth-child(3){
        max-width: 12%;
    }
    .details table thead tr th:nth-child(4){
        max-width: 13.5%;
    }
    .details table thead tr th:nth-child(5){
        max-width: 15%;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        top: 11px;
    }
    .details table tr td button.parsed, .details table tr td button.notParsed{
        padding: 9px 18px;
        width: 79px;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        left: 7px;
    }
    .details table thead tr th:nth-child(2){
        max-width: 16%;
    }
    .Switch{
        width: 40px;
        height: 21px;
    }
    .Switch span.On{
        display: none;
    }
    .Switch.On .Off{
        display: none;
    }
    .Switch.Off .Toggle{
        left: 51%;
        top: 3px;
        width: 14px;
        height: 14px;
    }
    
}
@media only screen and (min-width: 768px) and (max-width: 992px){
    body.active__minimal_sidebar .main__content_wrapper .main__content{
        width: calc(100% - 110px);
        margin-right: 11px;
        margin-left: 97px;
    }
    .details table .header th{
        font-size: 12px !important;
        font-weight: normal;
    }
    .Switch{
        width: 40px;
        height: 21px;
    }
    .Switch span.On{
        display: none;
    }
    .Switch.On .Off{
        display: none;
    }
    .Switch.Off .Toggle{
        left: 51%;
        top: 3px;
        width: 14px;
        height: 14px;
    }
    .Switch.On .Toggle{
        left: 8%;
        top: 3px;
        width: 14px;
        height: 14px;
    }
    .pagination_bar{
        padding: 0px 0 6px;
    }
    .details{
        padding: 13px 10px;
    }
    .tag_list h5{
        padding: 0px 10px;
        font-size: 16px;
    }
    .table_btn{
        background: unset;
        padding: 0 !important;
        font-size: 0px;
    }
    .table_btn span{
        border: 1px solid #2F80ED; 
        line-height: 34px;
        margin-left: -6px;
        margin-right: -1px;
        height: 26px;
        width: 26px;
    }
    .details table tr td:last-child a{
        margin-right: 2px;
    }
    .details table .header th{
        padding: 0px 15px;
    }
    .details table tr td {
        padding: 0px 15px;
    }
    .delete_btn span{
        border: 1px solid red;
    }
    .details table tr td{
        font-size: 12px;
    }
    .details table thead tr th:first-child{
        max-width: 18.8%;
    }
    .details table thead tr th:nth-child(3){
        max-width: 16%;
    }
    .details table thead tr th:nth-child(4){
        max-width: 14.5%;
    }
    .details table thead tr th:nth-child(5){
        max-width: 15%;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        top: 11px;
    }
    .details table tr td button.parsed, .details table tr td button.notParsed{
        padding: 9px 18px;
        width: 79px;
    }
    .details table tr td button.parsed .dot, .details table tr td button.notParsed .dot{
        left: 7px;
    }
    .details table thead tr th:nth-child(2){
        max-width: 26%;
    }
    .details table thead tr th:last-child{
        width: 13%;
    }
    .pagination_bar ul li a{
        padding: 3px 11px;
    }
    .pagination_bar ul li:nth-child(3) a{
        margin-top: -9px;
        padding: 2px 6px;
    }
    .pagination_bar ul li:nth-child(3) a span{
        margin-top: 9px;
        padding: 2px 6px;
    }
    .icon_text{
        font-size: 12px;
        color: rgba(19, 49, 89, 0.65);
    }
    .date_time .time,
    .date_time .date{
        font-size: 14px;
    }
    .system_button button{
        font-size: 12px;
        padding: 3px 19px;
    }
    .edit_button a{
        font-size: 12px;
        padding: 6px 7px;
    }
    .button_icon svg{
        height: 10px;
    }
    .button_icon {
        margin-right: 3px;
    }
    .records span,
    .add_new_user a{
        font-size: 12px;
    }
}


.modal_details.alt{
    top: 80px !important
}
.leadsrc_input_fileds .personalized__field{
    width: 100%;
}
.personalized__field .MuiInputBase-root input{
    border-bottom: unset;
    border: 1px solid #e7eff0;
    border-radius: 5px;
    width: 100%;
    padding: 0px 15px;
}
.personalized__field .MuiInputBase-root input:focus{
    box-shadow: unset !important;
    border-bottom: 1px solid #e7eff0 !important;
}