/* for note skeleton contact middle side*/
.ContactModule-container-parent-class .contact_center_field .contact__middle__inbox__wrapper .skeleton_each_note{
  display: flex;
  grid-gap: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  align-items: flex-end;
}

.ContactModule-container-parent-class .contact_center_field .contact__middle__inbox__wrapper .skeleton_each_note .skeleton_note_details {
    display: flex;
    flex-direction: row;
}
.ContactModule-container-parent-class .contact_center_field .contact__middle__inbox__wrapper .skeleton_each_note .skeleton_note_details .skeleton_note_edit_icon {
    position: absolute;
    right: 330px;
    margin-top: 20px;
}

.ContactModule-container-parent-class .contact_center_field .contact__middle__inbox__wrapper .skeleton_note_initial .skeleton_note_add_button {
    margin: 15px 0px 15px 330px;
}

