@media only screen and (min-width: 1024px) and (max-width: 1599px){
	.contact-top-filter-right > ul li a {
	    font-size: 12px;
	}

	.contact-top-filter-right > ul > li {
	    margin: 5px;
	}
	.contact-top-filter-left > ul li a {
	    font-size: 12px;
	}

	.contact-top-filter-left > ul li {
	    margin-right: 10px;
	}

	.body-search form input {
	    width: 100px !important;
	}
	span.contact-right-drop {
	    margin-left: 0px;
	}
	.contact-top-filter-left > ul li.add-contact > a svg {
	    width: 16px;
	    margin-right: 5px;
	}
	.contact-top-filter-right > ul li a svg {
	    width: 16px;
	    margin-right: 5px;
	}
	th.table-heading-title {
	    font-size: 13px;
	}
	span.information-name {
	    font-size: 13px;
	}
	td.option-information ul li a svg {
	    width: 20px;
	}
	td.option-information ul li {
	    margin: 2px;
	}
	td.Owner-content {
	    font-size: 13px;
	}
	td.Campaigns-content ul li {
	    font-size: 13px;
	}
	td.activite-content {
	    font-size: 13px;
	}
	td.single-content {
	    font-size: 13px;
	}
	td.hastag-content {
	    font-size: 13px;
	}
	td.new-content {
	    font-size: 13px;
	}
	.lead-owner-top h3 {
	    font-size: 14px;
	    padding: 0px 5px;
	}
	#progressbar li:first-child {
	    margin-left: -35px;
	}
	.manage-tag-modal {
	    max-width: 600px;
	}
	.multistep-form-modal{
		max-width: 825px !important;
	}
	#progressbar li {
	    width: 22%;
	}
	/* .upload-single-item-main{
		max-height: 236px;
	} */
	/* .upload-single-item-main .awesome__scroll_bar {
		height: 183px !important;
	} */
	th.table-heading-title span{
		margin-left: 30px;
	}
	
}
@media(max-width:1400px){
    #crumbs ul li	a {
	padding: 0px 20px 0 25px;
	font-size: 16px;
	}
}
@media only screen and (max-width: 1024px){
	.manage-tag-modal{
		width: 500px;
	}
	
	/* .upload-single-item-main{
		max-height: 300px;
	} */
	/* .upload-single-item-main .awesome__scroll_bar {
		height: 250px !important;
	} */

}
@media only screen and (max-width: 768px){
	.manage-tag-modal{
		width: 400px;
	}
	/* .upload-single-item-main{
		max-height: 208px;
	} */
	button.collaborators-search i{
		padding: 9px 9px;
	}
	.campaigns-single-item-main3.upload-single-item-main2.upload-single-item-main{
		margin-top: -9px;
	}
}