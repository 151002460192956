button, input, optgroup, select, textarea{
    font-family: var(--poppins);
}
.user__profile__wr {
    justify-content: center;
    display: flex;
    align-items: center;
}
.user__profile__avater{
    height: 62px;
    width: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--dark_blue);
    font-weight: 500;
    border-radius: 50%;
    border: 1px solid #E5E5E5;
    position: relative;
    z-index: 1;
}
.user__profile__avater .user__profile__h2{
    z-index: 2;
}
.user__profile__avater:after{
    position: absolute;
    content: '';
    background: var(--gray_light);
    height: 85%;
    width: 85%;
    border-radius: 50%;
    
}
.user__tabs__content .user__tabs__title{
    background: #F6F7FB;
    color: var(--dark_blue);
    font-size: 14px;
    text-transform: capitalize;
    flex: 1;
}
.user__tabs__title.MuiTab-textColorInherit{
    opacity: 1;
}
.user__tabs__title.Mui-selected{
    background-color: #3C7EF3;
    color: var(--white);
}
.user__tabs__content .user__tabs__title:first-child{
    border-top-left-radius: 5px;
    border-bottom-left-radius: 0px;
}
.user__tabs__content .user__tabs__title:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 0px;
}
.user__tabs__title.MuiTab-root{
    min-width: 30% !important;
}
.user__tabs__content .PrivateTabIndicator-colorSecondary-4{
    background-color: transparent !important;
}
/* .PrivateTabIndicator-colorSecondary-4{
    background-color: transparent !important;
} */

.use__tabs__panel .MuiBox-root{
    padding: 0;
}

.comon__title__name h6{
    font-size: 14px;
    color: var(--dark_blue) !important;
}
.ContactModule-container-parent-class .MuiTabs-indicator{
    display: none;
}
.contact_details_top .contact_details_action {
    color: #3C7EF3 !important;
    padding: 10px 8px 14px!important;
    height: 30px !important;
    width: 70px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(60, 126, 243, 0.1) !important;
    text-transform: capitalize;
    border-radius: 5px;
  }
  .contact_details_top .contact_details_action:focus{
    background-color: rgba(0,0,0,0.0) !important;
  }
.lead__status__select .MuiFormControl-root .MuiInput-input{
    width: 100%;
    box-shadow: 0 0 0 0 transparent,0 0 0 0 transparent,0 1px 1px 0 rgba(0,0,0,.12),0 0 0 1px rgba(60,66,87,.16),0 0 0 0 transparent,0 0 0 0 transparent,0 2px 5px 0 rgba(60,66,87,.08)!important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    position: relative !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500;
    color: #546376;
    padding: 10px 8px 14px!important;
    width: 100%;
}

.ContactModule-container-parent-class .addtional__email__input input:focus {
    box-shadow: 0 0 0 0 transparent,0 0 0 0 transparent,0 1px 1px 0 rgba(0,0,0,.12),0 0 0 1px rgba(60,66,87,.16),0 0 0 0 transparent,0 0 0 0 transparent,0 2px 5px 0 rgba(60,66,87,.08)!important;
    outline: none !important;
    border-bottom: none !important;
}

.lead__status__select .MuiFormControl-root{
    width: 100%;
}
.lead__status__select .MuiInput-underline:hover:not(.Mui-disabled):before{
    border-bottom: 0 !important;
}
.lead__status__select .MuiInput-underline:before{
    border-bottom: 0 !important;
}

.ContactModule-container-parent-class .___more_info__content .custom-field-contact {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

@media only screen and (max-width: 1500px){

    .user__tabs__content .user__tabs__title{
        font-size: 12px !important;
    }
}

/* left side single contact input field */
/* .text-field-item .small-round-icon.checkIcon {
    right: 38px;
} */

.text-field-item input {
    padding-right: 50px;
    box-sizing: border-box;
    padding-right: 36px !important;
}

.text-danger{
    color: rgb(244, 67, 54) !important;
}

.ContactModule-container-parent-class .contact-single-details-more-info-body .contact-details-single-custom-fields {
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;
}

.ContactModule-container-parent-class .contact-single-details-more-info-body .contact-details-single-custom-fields .details-custom-text-field{
    font-size: 12px;
    margin: 0px;
    padding: 0 0 0 5px;
    height: 30px;
    border: 1px solid #9e9e9e;
    border-radius: 12px;
}

.ContactModule-container-parent-class .contact-single-details-more-info-body .contact-details-single-custom-fields .details-custom-text-area-field{
    font-size: 12px;
    resize: none;
    margin-right: 10px;
    border: 1px solid #9e9e9e;
    border-radius: 6px;
    padding: 5px;
}

.ContactModule-container-parent-class .contact-single-details-more-info-body .custom-field-input-contact > .MuiGrid-container{
    border: 1px solid #9e9e9e;
    border-radius: 12px;
    /* height: 32px; */
}

.ContactModule-container-parent-class .contact-single-details-more-info-body .custom-field-input-contact .MuiGrid-container svg{
    width: 15px;
}

.ContactModule-container-parent-class .contact-single-details-more-info-body .custom-field-input-contact .MuiGrid-container button{
    padding:0px
}

.ContactModule-container-parent-class .contact-single-details-more-info-body .custom-field-input-contact .MuiGrid-container input.MuiInputBase-input{
    padding: 0;
    margin: 0;
    font-size: 12px;
    height: 30px;
}

.ContactModule-container-parent-class .contact-single-details-more-info-body .contact-custom-field-checkbox ul {
    padding: 0px;
    margin: 0px;
}


/* Additional Email field */
.ContactModule-container-parent-class .additional__email__field__wr {
    margin-bottom: 10px ;
}
.ContactModule-container-parent-class .additional__email__field__wr .MuiCardContent-root:last-child {
    padding: 0 ;
}
.ContactModule-container-parent-class .additional__email__field__wr .MuiCardContent-root{
    padding: 11px !important;
    color: var(--gray) !important;
}
.ContactModule-container-parent-class .additional__email__field__wr .additional__email__field__title{
    margin: 0 !important;
}
.ContactModule-container-parent-class .additional__email__field__wr .MuiCardContent-root p{
    margin: 8px 0;
}
.ContactModule-container-parent-class .addtional__eamail__btn__wr  .addtional__email__btn__container{
    display: flex;
    justify-content: end;
}
.ContactModule-container-parent-class .addtional__eamail__btn__wr .addtional__email__btn{
    background: var(--dark_blue);
    border: 1px solid var(--dark_blue);
    display: flex;
    justify-content: flex-end;
    text-align: right;
    padding: 8px 8px;
    font-size: 14px;
    color: #fff;
    border-radius: 6px;
    margin: 9px 0 0 0;
    cursor: pointer;
}

.ContactModule-container-parent-class .addtional__email__input input{
    box-shadow: 0 0 0 0 transparent,0 0 0 0 transparent,0 1px 1px 0 rgba(0,0,0,.12),0 0 0 1px rgba(60,66,87,.16),0 0 0 0 transparent,0 0 0 0 transparent,0 2px 5px 0 rgba(60,66,87,.08)!important;
    height: 44px !important;
    border-radius: 5px !important;
    padding-left: 10px !important;
    background: var(--white) !important;
    box-sizing: border-box !important;
    border-bottom: none;
}

.ContactModule-container-parent-class .addtional__email__sent__btn__remove{
    border: 1px solid var(--red);
    background: var(--red);
    color: #fff;
    font-size: 13px;
    padding: 4px 7px;
    border-radius: 6px;
    cursor: pointer;

}
.ContactModule-container-parent-class  .addtional__email__select__type{
    margin: 0 0 10px 0 !important;
}
.ContactModule-container-parent-class .addtional__email__sent__btn__save{
    border: 1px solid var(--green2);
    background: var(--green2);
    color: #fff;
    font-size: 13px;
    padding: 4px 7px;
    border-radius: 6px;
    cursor: pointer;
}
.ContactModule-container-parent-class .addtional__email__sent__btn__group{
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    margin-top: 10px;
}

.ContactModule-container-parent-class .additional__email__input__form {
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: #fff;
    padding: 17px;
    border-radius: 10px;
    margin-bottom: 10px;
}
.ContactModule-container-parent-class  .custom__card__content{
    display: flex;
    justify-content: space-between;
}
.ContactModule-container-parent-class  .custom__card__content__right span{
    display: inline-flex;
}
.ContactModule-container-parent-class  .custom__card__content__right__msg svg{
    width: 20px;
    height: 20px;
    cursor: pointer;
}
.ContactModule-container-parent-class  .custom__card__content__right__msg svg path{
    fill: var(--gray)
}
.ContactModule-container-parent-class  .custom__card__content__left {
    width: 70%;
}
.ContactModule-container-parent-class .custom__card__content__right {
    display: flex;
    justify-content: end;
    align-items: center;
    grid-gap: 6px;
}
.ContactModule-container-parent-class .custom__card__content__right .small-round-icon .MuiSvgIcon-root {
    font-size: 1.2rem;
}
.ContactModule-container-parent-class .additional__email__field__title{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.ContactModule-container-parent-class .additional__email__field__content{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}



.ContactModule-container-parent-class .contact_details_top__for__pagination{
    width: 100%;
}
.ContactModule-container-parent-class .contact_details_top__for__pagination .btn-floating{
    flex-shrink: 0;
}
.ContactModule-container-parent-class .contact_details_top__for__pagination .contact_details_top__for__info1{
    width: 100%;
    justify-content: space-between;
    display: inline-flex;
    align-items: center;
}
.ContactModule-container-parent-class .contact_details_top__for__pagination .contact_details_top__for__pagin{
    display: inline-flex;
    align-items: center;
    color: var(--gray);
    grid-gap: 4px;
}
.ContactModule-container-parent-class .contact_details_top__for__pagination .contact_details_top__for__pagin span {
    display: inline-flex;
}
.ContactModule-container-parent-class .contact_details_top__for__pagination .contact_details_top__for__pagin svg {
    display: inline-flex;
    color: var(--light_blue);
    cursor: pointer;
}

@media screen and (max-width:1366px) {
    .ContactModule-container-parent-class .custom__card__content{
        flex-direction: column-reverse;
    }
    /*.ContactModule-container-parent-class .custom__card__content__right {*/
    /*    margin-bottom: 16px;*/
    /*}*/
    .ContactModule-container-parent-class .custom__card__content__left {
        width: 100%;
    }
    
}

.ContactModule-container-parent-class .overwrite-h5 {
    margin: 0 !important;
}

.ContactModule-container-parent-class .fullNameUser {
    margin: 1.0933333333rem 0 .656rem 0;
    gap: 6px;
}