.show__all__campaigns {
  width: 100%;
}
.show__all__campaigns .contact__all__campaigns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  cursor: pointer;
}

.show__all__campaigns .campaign__contact__created__at {
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
}

.show__all__campaigns .running-campaigncontact-icon-contactlist {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: #47ed1d;
}

.show__all__campaigns .pause-campaign-contact-icon-contactlist {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background-color: firebrick;
}

.show__all__campaigns .campaign__show__all__table__header {
  text-align: center;
}

.show__all__campaigns .no__tag__added {
  display: flex;
  justify-content: center;
  grid-gap: 20px;
  align-items: center;
  margin-top: 100px;
  margin-left: 20%;
}

.show__all__campaigns .add__tag {
  background-color: #2c3e50dd;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  padding: 10px 15px;
  margin-right: 20px;
}

.show__all__campaigns .add__tag__top {
  background-color: #26a69a;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #f6f7fb;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  cursor: pointer;
  padding: 5px 10px;
  margin-left: 10px;
}

.show__all__campaigns .remove__tag {
  background-color: #e74c3c;
  border: none;
  border-radius: 6px;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  cursor: pointer;
  padding: 10px 15px;
}

/* .show__all__campaigns .show__more__tag__table__wr th,td {
  width: 50%;
} */
 
.show__all__campaigns .update__stage__btn {
  text-decoration: none;
  padding: 8px 10px;
  background-color: #2c3e50dd;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
}

.ContactModule-container-parent-class .Campaigns-content .see__more__contact__campaigns {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  align-items: center;
  border: 1px solid #ddd;
  padding: 4px 10px;
  border-radius: 6px;
  margin-top: 10px;
}

.ContactModule-container-parent-class .Campaigns-content .add__tags__btn__from__contact__list {
  margin-left: 15px;
  margin-top: 10px;
  border: 1px solid #ddd;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactModule-container-parent-class .Campaigns-content .add__tags__btn__from__contact__list svg {
  fill: #00cec9;
}