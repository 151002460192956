.ContactModule-container-parent-class .data-exports {
    margin:0;
    padding: 0;
}

.ContactModule-container-parent-class .data-exports .row {
    display: flex;
    /* flex: 1; */
    flex-direction: row;
    justify-content:space-between;
    margin: 0  !important;
}

.ContactModule-container-parent-class .data-exports .row .dropdown .dropdown-row {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
}

.ContactModule-container-parent-class .data-exports .row .dropdown .dropdown-row span {
    margin-top: 25px;
}

.ContactModule-container-parent-class .data-exports .export_table {
    border: none;
    margin-top: 0px;
}

/* .data-exports .row .dropdown .refresh-button {
  margin-left:60px;  
} */

/* pagination */

.ContactModule-container-parent-class .data-exports .pagination_bar {
    /* padding: 62px 0 20px; */
}
.ContactModule-container-parent-class .data-exports .pagination_bar ul  {
   display: flex;
   justify-content: center;
}
.ContactModule-container-parent-class .data-exports .pagination_bar ul li a {
    color:#fff;
    background: #3C7EF3;
    border-radius: 5px;
    padding: 6px 13px;
    margin-left:10px;
    display: block;
    border:2px solid #3C7EF3;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
}
.ContactModule-container-parent-class .data-exports .pagination_bar ul li:nth-child(2) a {
    background: transparent;
    border:2px solid #3C7EF3;
    color:#3C7EF3;
}
.ContactModule-container-parent-class .data-exports .pagination_bar ul li:nth-child(3) a {
    background: transparent;
    color:#3C7EF3;
    border: unset;
    padding: 0px 6px;
}
.ContactModule-container-parent-class .data-exports .pagination_bar ul li:nth-child(3) a span {
    margin-right: 5px;
}
.ContactModule-container-parent-class .data-exports .pagination_position {
    margin: 20px auto;
    /* position: fixed;
    bottom: 0;
    left: 25%;
    right: 20%; */
}


.ContactModule-container-parent-class .data-exports .dropdown{
    width: 50%;
}
.ContactModule-container-parent-class .data-exports .refresh-button{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.ContactModule-container-parent-class .data-exports .dropdown select{
    border: 1px solid rgba(19, 49, 89, 0.35);
    color: rgba(19, 49, 89, 0.65);
    font-size: 16px;
    width: 65px;
    margin: 0 10px;
    border-radius: 5px;
}
.ContactModule-container-parent-class .data-exports .refresh-button button{
    padding: 8px 15px;
    background: #3c7ef3;
    border-radius: 5px;
    font-family: var(--poppins);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: .01em;
    color: #fff;
    text-transform: capitalize;
}
.ContactModule-container-parent-class .data-exports .heading{
    border-bottom: 1px solid #e5e8ef;
    margin: 0 0 12px 0;
    padding: 0 0 25px 0;
}
.ContactModule-container-parent-class .data-exports .heading span{
    font-size: 20px;
    color: var(--dark_blue);
   
}

/* table design css */
.ContactModule-container-parent-class .data-exports .export_table td{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color:rgba(19,49,89,.65)
}
.ContactModule-container-parent-class .data-exports .data__export__comn__status{
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    border-radius: 16px;
    padding: 4px 15px;
}
.ContactModule-container-parent-class .data-exports .data-export-status-pending {
    background-color: var(--orange)
}

.ContactModule-container-parent-class .data-exports .data-export-status-processing {
    background-color: var(--green2);

}
.ContactModule-container-parent-class .data-exports .data-export-status-complete {
    background-color: var(--blue-1);
}
.ContactModule-container-parent-class .data-exports .data-export-status-failed {
    background-color: var(--red);

}
.data-export-file-table-empty, .data-export-file-table-loading{
    height: 100px;
}
.data-export-file-table-empty td, .data-export-file-table-loading td{
    text-align: center;
}

.data-export-heading-alt{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}
.data-export-heading-alt .data-export-back-button{
    padding: 5px 20px;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
    border-radius: 4px;
}
.data-export-heading-alt .data-export-back-button span{
    color: #FFF !important;
    font-size: 18px;
    margin-left: 5px;
}
.export-status-complete{
    align-items: center;
    display: flex;
}
.export-file-download-icon{
    align-items: center;
    display: flex;
    margin-left: 10px;
}

.export-file-delete-icon{
    align-items: center;
    display: flex;
    margin-left: 10px;
    cursor: pointer;
}


.data-export-heading-bread__alt{
    display: flex;
    align-items: center;
    grid-gap: 20px;
   
}
.data-export-heading-bread__alt h3{
    font-size: 20px;
}
.data-export-bradcumb-container .data-export-bradcumb-each{
    display: inline-flex;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.54);
}
.data-export-bradcumb-container .data-export-bradcumb-each:hover{
    text-decoration: underline;
    cursor: pointer;
}
.data-export-bradcumb-container .data-export-bradcumb-each:last-child{
    color:rgba(0, 0, 0, 0.87);
    text-decoration: none !important
}

.data-export-bradcumb-container ul.data-export-bradcumb-list {
    padding: 0;
    display: inline-flex;
    align-items: center;
    grid-gap: 5px;
}

.data-export-bradcumb-container{

}