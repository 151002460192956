/* for log skeleton contact middle side*/
.ContactModule-container-parent-class .contact_center_field .contact__middle__inbox__wrapper .skeleton_each_log{
  display: flex;
  grid-gap: 15px;
  padding-bottom: 15px;
  padding-top: 15px;
  align-items: flex-end;
}

.ContactModule-container-parent-class .contact_center_field .contact__middle__inbox__wrapper .skeleton_each_log .skeleton_log_details {
    display: flex;
    flex-direction: row;
}

