:root {
  --light-blue-10: rgba(60, 126, 243, 0.1);
  --yellow: #f2c94c;
  --yellow-10: rgba(242, 200, 76, 0.1);
  --shade: #f0efff;
  --green: #6fcf97;
  --orange: #f2994a;
  --green-10: rgba(39, 174, 95, 0.1);
  --red-10: rgba(255, 38, 74, 0.1);
  --dark-blue-05: rgba(19, 49, 89, 0.05);
  --dark-blue-10: rgba(19, 49, 89, 0.1);
  --dark-blue-65: rgba(19, 49, 89, 0.65);
}
button, input, optgroup, select, textarea, .MuiInputBase-root, .MuiFormLabel-root .MuiTypography-body1{
    font-family: var(--poppins) !important;
}
/*****************************
         Common Style
******************************/
body {
  overflow-x: hidden;
}
.text-gray {
  color: var(--gray) !important;
}
.text-light-blue {
  color: var(--light_blue);
}
.text-red {
  color: var(--red);
}
.text-dark-blue {
  color: var(--dark_blue);
}
.text-dark-blue-65 {
  color: var(--dark-blue-65);
}
.text-green {
  color: var(--green);
}
.text-orange {
  color: var(--orange);
}
.text-yellow {
  color: var(--yellow);
}
.bg-gray-light {
  background: var(--gray_light);
}
.bg-gray-dark {
  background: var(--gray_dark);
}
.bg-light-blue {
  background: var(--light_blue);
}
.bg-light-blue-10 {
  background: var(--light-blue-10);
}
.bg-dark-blue-10 {
  background: var(--dark-blue-10);
}
.bg-orange-10 {
  background: rgba(242, 152, 74, 0.1);
}
.bg-dark-blue-05 {
  background: var(--dark-blue-05);
}
.bg-red {
  background: var(--red);
}
.bg-red-10 {
  background: var(--red-10);
}
.bg-red-65 {
  background: rgba(255, 38, 74, 0.65);
}
.bg-yellow {
  background: var(--yellow);
}
.bg-yellow-10 {
  background: var(--yellow-10);
  /* background: rgb(8 8 8 / 10%); */
}
.bg-shade {
  background: var(--shade);
}
.bg-green {
  background: var(--green);
}
.bg-green-10 {
  background: var(--green-10);
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}
.text-left {
  text-align: left !important;
}
.radius-5 {
  border-radius: 5px !important;
}
.radius-10 {
  border-radius: 10px !important;
}
p,
h6 {
  font-size: 14px;
}
h5 {
  font-size: 16px;
  color: var(--dark_blue);
}

/*****************************
      Common Input field
******************************/
.text-field-item {
  position: relative;
}
.text-field {
  border: none !important;
  height: 44px !important;
  border-radius: 5px !important;
  padding-left: 10px !important;
  background: var(--white) !important;
  box-sizing: border-box !important;
}
.small-round-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: rgba(60, 126, 243, 0.1) !important;
  color: var(--light_blue);
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}
.text-field-item .small-round-icon {
  position: absolute;
  top: 14px;
}
.small-round-icon i {
  font-size: 14px;
}
.text-field:focus {
  box-shadow: none !important;
  border: 0 !important;
  outline: 0 !important;
}
/*****************************
      Outline button
******************************/
.profile-outline-btn {
  width: 100%;
  height: 50px;
  background: var(--white);
  font-size: 15px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  box-shadow: none;
  text-transform: capitalize;
  border-radius: 6px;
}
.profile-outline-btn:hover,
.profile-outline-btn:focus {
  background: var(--white);
}
/*  */
.contact_details {
  border-radius: 10px;
}
.action_selection {
  background: transparent;
  border: 1px solid rgba(60, 126, 243, 0.1);
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  color: var(--light_blue);
}
.action_selection:focus {
  outline: 0;
}
.contact_person_icon {
  background: rgba(60, 126, 243, 0.1) !important;
}
.contact_person_icon i {
  color: var(--light_blue);
}

.contact_details_top {
  flex-wrap: nowrap;
  border-bottom: 2px solid var(--gray_light);
}

.user_profile_name h5 {
  font-size: 16px;
  position: relative;
}
.edit_share_icon {
    position: absolute;
    top: 10px;
    transform: translateY(-50%);
}
.edit_share_icon i {
  font-size: 15px;
}

.user_profile_icons .single_icon i {
  border: 1px solid var(--gray);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  transition: 0.3s linear;
}
.user_profile_icons .single_icon i:hover {
  background: var(--light_blue);
  border: 1px solid var(--light_blue);
  color: var(--white) !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25) !important;
}
.tab_buttons {
  border-radius: 5px;
  overflow: hidden;
}

.user_profile_tab_item:hover,
.user_profile_tab_item .active {
  background: var(--light_blue) !important;
  border-color: var(--light_blue) !important;
}

.user_profile_tab_item .tabs-items {
  text-transform: capitalize;
}
.user_profile_tab_item .tabs-items:hover,
.user_profile_tab_item .tabs-items.active {
  color: var(--white) !important;
}
.indicator {
  display: none;
}

/*****************************
      more info part
******************************/

.more_info_header {
  border-bottom: 1px solid var(--gray_dark);
}
.more_info_header span {
  background: var(--light_blue);
  height: 26px;
  width: 26px;
  cursor: pointer;
}

/*****************************
         tags part
******************************/
.tags_header span {
  background: rgba(60, 126, 243, 0.1);
  height: 26px;
  width: 26px;
  cursor: pointer;
}
.tag_items {
  flex-wrap: wrap;
}
.tag_items span {
  /* border: 0.5px solid var(--gray_dark); */
  font-size: 10px;
  border-radius: 5px;
}
.tag_items span i {
  height: 14px;
  width: 14px;
  font-size: 12px;
  padding: 1px;
}

.center_field_tabs {
  border-radius: 10px;
}
.center_field_top {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.05);
}
.center_field_top .tab svg {
  fill: var(--gray);
}
.center_field_top a:hover p,
.center_field_top a:hover i,
.center_field_top .tab .active p,
.center_field_top .tab .active i {
  color: var(--light_blue) !important;
}
.center_field_top a:hover svg,
.center_field_top .tab .active svg {
  fill: var(--light_blue) !important;
}
.conversation_container {
  max-height: 100vh !important;
}
.single_message_box {
  width: 100%;
}
.single_message_box,
.single_note {
  padding: 25px 55px;
  position: relative;
}
.single_note {
  border-radius: 0 0 10px 10px;
}
.single_message {
  border-radius: 10px 10px 10px 0;
}
.message_header {
  border-bottom: 1px solid var(--white);
}
.note_header {
  border-bottom: 1px solid rgba(19, 49, 89, 0.05);
  white-space: nowrap;
}
.message_header.outgoing_call {
  border-bottom: 1px solid var(--gray_dark);
}
.lead_src_drp input{
  background: #FFFFFF !important;
  border: 1px solid #E5E8EF !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  z-index: 0 !important;
  font-size: 16px !important;
  line-height: 22px;
  letter-spacing: 0.01em;
  padding: 0px 0 0 47px !important;
  color: #8896A8 !important;
  position: relative !important;
}

.lead_src_drp span.drp_iocn{
  position: absolute;
  right: 0;
  left: unset !important;
  background: unset;
}
.lead_src_drp ul li span {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: rgb(19, 49, 89);
  text-transform: capitalize;
  padding: 13px 11px 0px 19px;
}

.lead_src_drp .caret{
  display: none;
}
.single_message_type_icon,
.single_note_icon {
  padding: 10px;
  box-sizing: content-box;
}
.single_message_type_icon i {
  font-size: 15px;
}
.single_message_type_icon.email {
  background: rgba(60, 127, 243, 0.5);
}
.single_message_type_icon.note {
  background: rgba(242, 200, 76, 0.2);
}
.single_message_type_icon.call {
  background: var(--dark-blue-10);
}
.single_message_type_icon.file {
  background: rgba(255, 38, 74, 0.1);
}

.single_message_box > img {
  height: 40px;
  width: 40px;
  position: absolute;
}
.single_message_box .left-side {
  left: 0;
}
.single_message_box .right-side {
  right: 0;
}
.user_message_status {
  position: absolute;
  left: 8px;
  top: 70px;
  height: 25px;
  width: 25px;
}
.user_message_status i {
  font-size: 16px;
}
.show_more_btn {
  border-radius: 5px;
  text-transform: none;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  box-shadow: none;
  margin-top: 1em;
}
.show_more_btn.bg-light-blue:hover,
.show_more_btn.bg-light-blue:focus {
  background: var(--light_blue);
}
.show_more_btn.bg-yellow:hover,
.show_more_btn.bg-yellow:focus {
  background: var(--yellow);
}
.show_more_btn.bg-red:hover,
.show_more_btn.bg-red:focus {
  background: rgba(255, 38, 74, 0.65);
}
.show_more_btn.bg-gray-dark:hover,
.show_more_btn.bg-gray-dark:focus {
  background: var(--gray_dark);
}
/* .single_message_box.left .message_header{
  display: flex;
} */
video,
audio {
  max-width: 100%;
  /* border: 0 !important; */
}
/*****************************
    center field tab part
******************************/
.center_field_tabs .tabs .tab a:hover,
.center_field_tabs .tabs .tab a.active {
  background: var(--white);
}
/*****************************
     activities tab part
******************************/
.activities_tab_header {
  overflow: auto;
}
.activities_tab_header a {
  white-space: nowrap;
  font-size: 16px;
}
.activities_buttons_groups {
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: rgba(19, 49, 89, 0.05);
}
.activities_buttons_groups .button_group a svg {
  fill: var(--dark-blue-65);
}
.activities_buttons_groups .button_group {
  height: 40px;
  overflow: hidden;
}
.activities_buttons_groups .button_group a {
  border: 1px solid var(--gray_dark);
  transition: 0.3s linear;
  height: 100%;
  font-size: 14px;
}
.activities_buttons_groups .button_group a:hover i,
.activities_buttons_groups .button_group a.active i {
  color: var(--white);
}
.activities_buttons_groups .button_group a:hover,
.activities_buttons_groups .button_group a.active {
  color: var(--white);
  background: var(--light_blue) !important;
  border-color: var(--light_blue);
}
.activities_buttons_groups .button_group a:hover svg {
  fill: var(--white);
}
.activities_items_table h5,
.activities_items_table thead label span {
  font-size: 20px !important;
}
.activities_items_table tbody label span {
  font-size: 14px;
  color: rgba(19, 49, 89, 0.5);
}
.activities_items_table tr {
  border-bottom: 3px solid rgba(60, 127, 243, 0.06);
}
.activities_items_table input[type="checkbox"].filled-in:checked ~ span {
  color: var(--light_blue);
}
.activities_items_table input[type="checkbox"].filled-in:checked tr {
  background: var(--light_blue) !important;
}
.activities_items_table
  input[type="checkbox"].filled-in
  + span:not(.lever):after {
  border-radius: 5px !important;
}
.activities_items_table
  input[type="checkbox"].filled-in
  + span:not(.lever):after {
  border-color: rgba(60, 127, 243, 0.35) !important;
}
.activities_items_table
  input[type="checkbox"].filled-in:checked
  + span:not(.lever):after {
  background: var(--light_blue) !important;
  border-color: var(--light_blue) !important;
}

/*****************************
  files tab and log tab part
******************************/
#files_tab,
#log_tab {
  height: 100vh;
}
.files_tab_single_file,
.log_tab_single_file {
  border-bottom: 3px solid rgba(60, 127, 243, 0.06);
  transition: 0.3s linear;
}
.files_tab_single_file .files_tabs_time,
.log_tab_single_file .log_tabs_time {
  font-size: 12px;
}
.log_tab_single_file:hover {
  background: var(--light-blue-10);
}
.log_tab_single_file:hover .log_tab_heading {
  color: var(--dark_blue);
}

/*****************************
    collapsible right part
******************************/
.lead_owner_collapsible {
  border-radius: 10px;
  white-space: nowrap;
}
.lead_owner_collapsible .collapsible {
  box-shadow: none;
  border: 0;
}
.collapsible-header svg {
  fill: var(--gray);
}
.collapsible-header .items_counter {
  height: 26px;
  width: 26px;
}
.lead_owner_collapsible .collapsible .active .collapsible-header {
  background: var(--dark_blue);
  color: var(--white);
  border-radius: 10px 10px 0 0;
}
.lead_owner_collapsible .collapsible .active .collapsible-header h5 {
  color: var(--white);
}
.lead_owner_collapsible
  .collapsible
  .active
  .collapsible-header
  i.text-dark-blue {
  color: var(--white);
  transform: rotate(180deg);
}
.lead_owner_collapsible .active .collapsible-header .collapsible_add_more_btn {
  display: flex !important;
  height: 26px;
  width: 26px;
}

.lead_owner_collapsible .collapsible .active .collapsible-header svg {
  fill: var(--white) !important;
}
.lead_owner_collapsible .collapsible-header {
  border: 0;
}
.lead_owner_collapsible .collapsible-body {
  border: 0;
}

.collapsible_first_item {
  border-radius: 10px 10px 0 0;
  white-space: nowrap;
}
.collapsible_last_item {
  border-radius: 0 0 10px 10px;
  white-space: nowrap;
}

.deals_btn_collapsible {
  border-radius: 5px;
}
.won_lost_btn {
  border: 1px solid;
  border-radius: 40px;
  height: 24px;
}
.won_lost_btn i {
  font-size: 14px;
}

.running_btn_campaigns {
  height: 20px;
  border-radius: 10px;
}
.running_btn_campaigns span {
  font-size: 10px;
}
.running_btn_campaigns i {
    font-size: 14px;
    width: 14px;
}

/* input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
} */
.now_schedule_later_tab .tab a {
  text-transform: capitalize;
}

/* switch */
#ContactModule-container .single__contact_height .side_content_row .switch-icon {
  position: absolute;
  width: 19px;
  height: 45px;
  top: 0;
  z-index: 1;
  right: -20px;
  background: #3c7ef3;
  box-shadow: 0px 20px 15px rgb(60 126 243 / 24%);
  border-radius: 0px 10px 10px 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

#ContactModule-container .single__contact_height .side_content_row .switch-icon svg {
  margin-right: 3px;
  pointer-events: all;
}
/*****************************
      tiny text editor
******************************/
.tox.tox-tinymce {
  border: 0;
  border-radius: 10px;
}
.tox-statusbar {
  display: none !important;
}
.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  border: 1px solid var(--gray_dark);
  border-radius: 10px 10px 0 0;
}
/*****************************
        audio Player
******************************/

.audio-setup {
  margin-left: 0;
}

.audio-setup ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
}

.audio-setup ul li {
  list-style-type: none;
  margin-right: 7px;
  vertical-align: middle;
}

.audio-setup ul li.range-field {
  width: 100px;
}

input[type="range"] {
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: #3c7ef3;
  border: none;
  border-radius: 12px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: #fff;
  margin-top: -5px;
  border: 2px solid #fff;
}

input[type="range"]:focus {
  outline: none;
  background: #d9e6fd;
}

input[type="range"] {
  /* fix for FF unable to apply focus style bug  */
  border: 1px solid transparent;
  vertical-align: middle;
  margin: 0;

  /*required for proper track sizing in FF*/
  width: 100%;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 10px;
  background: #3c7ef3;
  border: none;
  border-radius: 12px;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border: 2px solid #fff;
  border-radius: 100%;
  background: #3c7ef3;
}

/*hide the outline behind the border*/
input[type="range"]:-moz-focusring {
  outline: 1px solid white;
  outline-offset: -1px;
}

input[type="range"]:focus::-moz-range-track {
  background: #d9e6fd;
}

input[type="range"]::-moz-range-progress {
  background-color: #3c7ef3;
  height: 10px;
  border-radius: 10px;
}

input[type="range"]::-moz-range-track {
  background-color: #d9e6fd;
  height: 10px;
  border-radius: 10px;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 10px;

  /*remove background color from the track, instead backgrond we'll use ms-fill-lower and ms-fill-upper */
  background: transparent;

  /*leave room for the larger thumb to overflow with a transparent border */
  border-color: transparent;
  border-width: 10px 0;

  /*remove default tick marks*/
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #3c7ef3;
  border-radius: 12px;
}

input[type="range"]::-ms-fill-upper {
  background: #dddddd;
  border-radius: 12px;
}

input[type="range"]::-ms-thumb {
  border: none;
  height: 16px;
  width: 16px;
  border-radius: 100%;
  background: #3c7ef3;
  border: 2px solid #fff;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #3c7ef3;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #d9e6fd;
}

/*****************************
        Modal Part Css
******************************/
.modal {
  top: 80px !important;
}
/* all info modal */
#view_all_info_modal {
  height: 95vh !important;
}
.all_info_content {
  border: 1px solid var(--gray_dark);
  border-radius: 0 0 10px 10px;
}
.all_info_text_field {
  position: relative;
}
.all_info_text_field .all_info_field_item {
  border: 1px solid var(--gray_dark) !important;
  height: 44px !important;
  border-radius: 5px !important;
  padding-left: 45px !important;
  background: var(--white) !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
}
.all_info_text_field span {
  height: 32px;
  width: 32px;
  position: absolute;
  left: 6px;
  top: 6px;
  background: var(--gray_dark);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* mini modal part css*/
#text_message_btn,
#email_btn,
#more_option_icon {
  position: relative;
}
#text_message_mini_modal,
#email_mini_modal,
#more_option_mini_modal {
  width: 100px;
  top: 60px !important;
  left: -20px !important;
  overflow: visible;
}
#text_message_mini_modal a:hover,
#email_mini_modal a:hover,
#more_option_mini_modal a:hover {
  color: var(--light_blue) !important;
}
#text_message_mini_modal::before,
#email_mini_modal::before,
#more_option_mini_modal::before {
  content: "";
  position: absolute;
  left: 30px;
  top: -15px;
  z-index: 99;
  border-bottom: 15px solid var(--white);
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}
/* SMS modal */
/* .text_editor_options_container {
  border-radius: 10px 10px 0 0;
} */

#text_message_sms_modal,
#text_message_mms_modal,
#email__modal,
#video_email__modal,
#voice_mail_modal,
#direct_mail_modal,
#add_note_modal,
#add_task_modal{
  max-width: 1170px;
  border-radius: 10px 0 0 10px;
}
#activities_modal{
  max-width: 805px;
  border-radius: 10px 0 0 10px;
}
.send__immediately {
  font-size: 20px;
  margin: 0;
}
select.quick_reply,
select.pre_recorded {
  -moz-appearance: none;
  -webkit-appearance: none;
}
/* phone modal */
#user_profile_dial_call {
  overflow-y: auto;
  width: 320px !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
  left: unset !important;
}
#user_profile_dial_call svg {
  top: 30px;
}
#user_profile_dial_call::before {
  background: url("");
}
/* email modal */
select.modal_bordered_select_input.pre_recorded,
select.modal_bordered_select_input.quick_reply {
  background-image: url(./assets/img/badge.png);
  background-repeat: no-repeat;
  background-size: 0.8em auto;
  background-position: 10px center;
  padding-left: 30px !important;
}
/* voice mail modal */

.modal {
  outline: 0 !important;
  max-width: 100%;
  max-height: 88%;
}
.modal-content .modal_header {
  border-bottom: 2px solid var(--white);
  padding-bottom: 15px;
}
.modal-content .modal_header h4 {
  font-size: 20px;
}
.modal_bordered_select_input,
.modal_bordered_text_field {
  border: 1px solid var(--gray_dark) !important;
  color: var(--gray);
  box-shadow: none !important;
  padding-left: 15px !important;
  box-sizing: border-box !important;
}
.form-select select{
  background: #fafafa  !important;
}
.form_input input{
  background: var(--white) !important;
}
.modal_bordered_select_input:focus,
.modal_bordered_text_field:focus {
  outline: 0;
}
.voice_mail_icon span,
.voice_mail_icon label {
  height: 40px;
  width: 40px;
}
.voice_mail_sending_tab {
  margin: 0 auto;
}
.voice_mail_sending_tab .tabs .tab a {
  color: var(--light_blue);
}
.voice_mail_sending_tab .tabs .tab a:hover,
.voice_mail_sending_tab .tabs .tab a.active {
  background: var(--light-blue-10);
}
.voice_mail_sending_tab .tabs {
  max-width: 400px;
}
.modal_footer_buttons a i {
  font-size: 16px;
}
.voice_mail_sending_tab > h5,
.voice_mail_send_now > p {
  font-size: 20px;
}
/* video mail modal */
.video_email_bg_icon {
  background: url(./assets/img/video.png);
  background-repeat: no-repeat;
  background-position: center;
}
/* direct mail modal */
.direct_mail_pick_btns {
  white-space: nowrap;
}
.direct_mail_pick_btns a {
  min-width: 150px;
}
/* add task modal  */
#add_task_modal textarea {
  height: 100px;
}
.add_task_top_buttons a:hover {
  background: var(--light_blue) !important;
  color: var(--white) !important;
}
.add_task_top_buttons a svg {
  fill: var(--gray);
  transition: 0.3s linear;
}
.add_task_top_buttons a:hover svg {
  fill: var(--white);
}
.add_task_reminder_time {
  max-width: 140px;
  height: 30px;
  font-size: 16px;
  color: var(--dark_blue);
}


/* asdfkna fawe */
.prerecod_drp{
  border: 1px solid rgba(60, 126, 243, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  padding: 10px;
  width: 168px;
  cursor: pointer;
}
.prerecod_drp span{
  margin-right: 12px;
}
.precord_drp_content {
  position: absolute;
  top: 100%;
  transform: translateY(-10px);
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s linear;
  padding: 10px 20px;
  width: 370px;
}
.precord_drp_content ul{
  margin: 0;
}
.precord_drp_content ul li{
  border-bottom: 1px solid rgba(19, 49, 89, 0.05);;
}
.precord_drp_content ul li:last-child{
  border-bottom: unset;
}
.show_sr .precord_drp_content {
  transform: translateY(0px);
  visibility: visible;
  opacity: 1;
  background: white;
  z-index: 1;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 3%);
  border-radius: 10px;

}
.playlist_top_content{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.select_playlist{
  display: flex;
  align-items: center;
}
.select_playlist form p{
  margin: 0;
  width: 70px;
}
.select_playlist form{
  margin-left: -24px;
  width: 50px;
}
.playlist_top_content p{
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
}
.playlist_top_content span{
  font-size: 12px !important;
  line-height: 20px !important;
  color: rgba(36, 79, 134, 0.5)  !important;
  margin: 0  !important;
  padding: unset !important;
}
.playlist_top_content span:hover{
  color: unset;
}
.collaborat__list.common__list .MuiIconButton-root svg path,
li.common__list .MuiAccordionSummary-content svg path,
.collapsible-header svg {
    fill: var(--gray);
}
/* ============Media Query ============*/
@media (max-width: 1800px) {
  .activities_tab .activities_tab_header a {
    font-size: 12px;
  }
  .activities_buttons_groups .button_group a {
    font-size: 12px;
  }
}
@media (max-width: 1675px) {
  .lead_owner_collapsible .collapsible-header h5 {
    font-size: 13px;
  }
  .single_message .message_header {
    white-space: nowrap;
  }
  .single_message .message_header h5 {
    font-size: 12px;
  }
  .single_message .message_header p {
    font-size: 10px;
  }
  .activities_tab .activities_tab_header a {
    font-size: 10px;
  }
  .activities_tab .activities_tab_header a i {
    font-size: 15px;
  }
  .activities_tab .activities_tab_header a svg {
    height: 12px !important;
    width: 12px !important;
  }
  .activities_tab .activities_buttons_groups .button_group a i {
    font-size: 15px;
  }
  .activities_tab .activities_buttons_groups .button_group a svg {
    height: 14px;
    width: 14px;
  }
  .activities_tab .activities_items_table thead h5,
  .activities_tab .activities_items_table thead tr span {
    font-size: 13px !important;
  }
  .activities_tab .activities_items_table tbody h5,
  .activities_tab .activities_items_table tbody p,
  .activities_tab .activities_items_table tbody tr span {
    font-size: 12px !important;
  }
}
@media (max-width: 1630px) {
  .activities_tab .activities_tab_header a {
    font-size: 8px;
  }
  .activities_tab .activities_tab_header a i {
    font-size: 13px;
  }
  .activities_tab .activities_tab_header a svg {
    height: 10px !important;
    width: 10px !important;
  }
  .activities_tab .activities_buttons_groups .button_group a {
    font-size: 10px;
    padding: 5px !important;
  }
}
@media (max-width: 1500px) {
  .contact_details_top span {
    font-size: 12px;
  }
  .contact_details_top .action_selection {
    width: 100%;
  }
  .user_profile_icons .single_icon i {
    margin: 5px !important;
    font-size: 15px;
  }
  .user_profile_icons .single_icon p {
    font-size: 12px;
  }
  .user_profile_tab_item .tabs-items {
    font-size: 12px !important;
  }
  .text-field-item .text-field {
    font-size: 12px !important;
  }
  .center_field_top .tab svg {
    height: 12px;
  }
  .center_field_top .tab a p {
    font-size: 12px;
  }

  .lead_owner_collapsible .collapsible-header h5 {
    font-size: 11px;
  }
  .lead_owner_collapsible .collapsible-header svg {
    width: 15px !important;
    height: 15px !important;
  }
  .lead_owner_collapsible .collapsible-header .collapsible_item_icon {
    margin: 0 5px 0 0 !important;
    display: flex;
    align-items: center;
  }
  .collapsible_first_item h5,
  .collapsible_first_item span,
  .collapsible_last_item h5,
  .collapsible_last_item span {
    font-size: 12px;
  }
  .collapsible_first_item i {
    margin: 0 5px 0 0 !important;
    font-size: 18px;
  }
  .collapsible_last_item > div {
    margin: 0 5px 0 0 !important;
  }
  .collapsible_last_item svg {
    height: 15px;
    width: 15px;
  }
  .deals__collapsible {
    padding: 0 !important;
  }
  .deals__collapsible .deals_btn_collapsible {
    padding: 3px 10px !important;
  }
  .campaigns__collapsible {
    padding: 0 !important;
  }
  .campaigns__collapsible h6 {
    font-size: 10px;
  }
  .completed_form_collapsible h6 {
    font-size: 10px;
  }
  .message__container {
    padding: 5px !important;
  }
  .single_message_box {
    padding: 25px 40px;
  }
  #conversation_tab {
    padding: 0;
  }
  .single_message .message_body p {
    font-size: 12px;
  }
  .log_tab_single_file h6 {
    font-size: 12px;
  }
  .log_tab_single_file .log_tabs_time {
    font-size: 10px;
  }
  .log_tab_single_file svg {
    height: 15px;
    width: 15px;
  }
}

@media (max-width: 1430px) {
}
@media (max-width: 1360px) {
  .single_message_box.left {
    padding-right: 5px;
  }
  .single_message_box.right {
    padding-left: 5px;
  }
  .activities_tab .activities_tab_header a {
    padding: 5px !important;
  }
  .activities_tab .activities_tab_header span i {
    font-size: 15px;
  }
  .activities_tab .activities_buttons_groups .button_group a {
    font-size: 8px;
  }
  .log_tab_single_file h6 {
    font-size: 10px;
  }
  .log_tab_single_file .log_tabs_time {
    font-size: 8px;
  }
}

@media (max-width: 1320px) {
  .activities_tab .activities_buttons_groups .button_group {
    height: 30px;
  }
  .activities_tab .activities_buttons_groups .button_group a i {
    font-size: 12px;
  }
  .activities_tab .activities_buttons_groups .button_group a svg {
    height: 11px;
    width: 11px;
  }
}
@media (max-width: 1280px) {
  .center_field_top .tab svg {
    height: 10px;
    margin: 0 !important;
  }
  .center_field_top .tab a p {
    font-size: 13px;
  }
  .won_lost_btn {
    font-size: 10px;
  }
  .running_btn_campaigns span {
    font-size: 8px;
    margin: 0 2px !important;
  }
  .running_btn_campaigns i {
    font-size: 10px;
  }
  .single_message .message_header h5 {
    font-size: 10px;
  }
  .single_message .message_header p {
    font-size: 8px;
  }
  .activities_tab .activities_tab_header a {
    font-size: 7px;
  }
  .activities_tab .activities_tab_header a i {
    font-size: 11px;
    margin-right: 2px !important;
  }
  .activities_tab .activities_tab_header a svg {
    height: 8px !important;
    width: 8px !important;
    margin-right: 2px !important;
  }
  .activities_tab .activities_buttons_groups .button_group a {
    font-size: 7px;
  }
  .activities_tab .activities_buttons_groups .button_group a i {
    font-size: 10px;
  }
  .activities_tab .activities_buttons_groups .button_group a svg {
    height: 9px;
    width: 9px;
  }
  .activities_tab .activities_items_table thead h5,
  .activities_tab .activities_items_table thead tr span {
    font-size: 10px !important;
  }
  .activities_tab .activities_items_table tbody h5,
  .activities_tab .activities_items_table tbody p,
  .activities_tab .activities_items_table tbody tr span {
    font-size: 9px !important;
  }
  .activities_tab .activities_items_table tbody tr label i {
    font-size: 15px;
  }
  .note_header h5 {
    font-size: 12px;
  }
  .note_header p {
    font-size: 11px;
  }
}
@media (max-width: 1180px) {
  .contact_center_field {
    padding: 0 10px !important;
  }
  .contact_person_icon {
    height: 25px;
    width: 25px;
  }
  .contact_person_icon i {
    font-size: 15px;
  }
  .contact_details_top span {
    font-size: 10px;
  }
  .user_profile_icons .single_icon i {
    margin: 2px !important;
    font-size: 10px;
  }
  .user_profile_icons .single_icon p {
    font-size: 9px;
  }
  .collapsible_first_item h5,
  .collapsible_first_item span,
  .collapsible_last_item h5,
  .collapsible_last_item span {
    font-size: 10px;
  }
  .collapsible_first_item span,
  .collapsible_last_item span {
    margin-left: 5px !important;
  }
  .collapsible-header .items_counter {
    height: 16px;
    width: 16px;
  }
  .collapsible-header .collapsible_add_more_btn {
    height: 16px !important;
    width: 16px !important;
    padding: 0 !important;
    font-size: 14px;
  }
  .deals__collapsible .deals_btn_collapsible {
    padding: 3px 7px !important;
  }
  .won_lost_btn {
    font-size: 8px;
    margin: 0 0 0 5px !important;
  }
  .running_btn_campaigns {
    padding: 0 2px !important;
  }
  .single_message_box > img {
    height: 25px;
    width: auto;
    top: 35px;
  }
  .single_message_box.left {
    padding-left: 25px;
  }
  .single_message_box.right {
    padding-right: 25px;
  }
  .activities_tab .activities_buttons_groups .button_group a {
    padding: 3px !important;
    font-size: 6px;
  }
  .activities_tab .activities_buttons_groups .button_group a i {
    font-size: 9px;
  }
  .activities_tab .activities_buttons_groups .button_group a svg {
    height: 8px;
    width: 8px;
  }
}
@media (max-width: 1100px) {
  .user_profile_tab_item .tabs-items {
    font-size: 10px !important;
  }
  .single_message_type_icon,
  .single_note_icon {
    padding: 7px;
  }
  .single_message .message_header h5 {
    font-size: 8px;
  }
  .single_message .message_header p {
    font-size: 7px;
  }
  .profile-outline-btn {
    font-size: 12px;
  }
}
@media (max-width: 992px) {
  .activities_tab .activities_buttons_groups .button_group a {
    padding: 5px !important;
    font-size: 10px;
  }
  .activities_tab .activities_buttons_groups .button_group:first-child a {
    width: 30px;
  }
  .activities_tab .activities_buttons_groups .button_group a i {
    font-size: 15px;
  }
  .activities_tab .activities_buttons_groups .button_group a svg {
    height: 14px;
    width: 14px;
  }
  .single_message .message_header h5 {
    font-size: 12px;
  }
}


/* =========Select Global Css========== */
.selects_fields{
  margin: 0 !important;
}
.selects_fields input{
  border: 1px solid rgba(60, 126, 243, 0.1) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  position: relative !important;
  font-size: 14px !important;
  line-height: 18px !important;
  font-weight: 500;
  color: #546376;
}
.selects_fields ul li{
  min-height: unset !important;
}
.selects_fields ul.select-dropdown{
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.03);
  border-radius: 10px;
  top: 30px !important;
}
.selects_fields ul li span{
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #133159;
  text-transform: capitalize;
  padding: 13px 11px 0px 19px;
}
.selects_fields ul li:last-child span{
  padding-bottom: 10px;
}
.selects_fields ul li:hover{
  background-color: unset !important;
}
.selects_fields ul li.selected{
  background-color: unset !important;
}
.selects_fields ul li span:hover{
  color: #3C7EF3;
}
.selects_fields .select-wrapper .caret{
  display: none;
}



/* -----Action select field------ */
.action_select input{
  color: #3C7EF3 !important;
  padding: 10px 8px 14px!important;
  height: 30px !important;
  width: 70px !important;
}
.action_select svg{
  position: absolute;
  right: 5px;
  top: 13px;
}
/*======Select Conversation field=======*/
.input_conversation input{
  padding: 10px 8px 14px!important;
  color: #3C7EF3 !important;
}
.input_conversation svg{
  position: absolute;
  right: 11px;
  top: 21px;
}
/*======Select Lead status field=======*/
.lead_status input{
  padding: 10px 8px 14px!important;
  background: white;
}
.lead_status svg{
  position: absolute;
  right: 11px;
  top: 21px;
}
/*======Select Custom Dropdown field=======*/
.custom_dropdown .select-wrapper .caret{
  display: unset !important;
  fill: #3C7EF3;
  right: 5px;
  z-index: 1;
}
.custom_dropdown input{
  background-color: white !important;
  padding: 12px 10px !important;
  color: #546376;
}
/*======Select remind field=======*/
.remind_select{
  margin: 0 !important;
}
.remind_select input{
  padding: 10px 20px !important;
  height: 30px !important;
  width: 140px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  color: var(--dark_blue);
}
.remind_select svg{
  position: absolute;
  right: 20px;
  top: 13px;
}

/*=========Email select field=========*/
.email_select  input{
  padding: 17px 146px 17px 20px!important;
  background-color: white !important;
  color: #546376;
}
.email_select svg{
  position: absolute;
  right: 17px;
  top: 19px;
  z-index: 1;
}
/*=========Phn No select field=========*/
.phnno_select .phno_select_title{
  position: absolute;
  left: 9px;
  top: 11px;
}
.phnno_select input{
  padding: 17px 20px 17px 60px !important;
  color: #546376;
}
.phnno_select svg{
  position: absolute;
  right: 17px;
  top: 19px;
}
/*=========Pre Record select field=========*/
.pre_rec  input{
  padding: 0px 40px !important;
  width: 168px !important;
  color: #133159;
}
.pre_rec .pre_rec_icon{
  position: absolute;
  left: 20px;
  top: 15px;
}
/*=========Email select field=========*/
.email_send_select input{
  background-color: white !important;
  padding: 0 20px !important;
}
.email_send_select svg{
  position: absolute;
  right: 17px;
  top: 19px;
  z-index: 1;
}
.subject_input input{
  background-color: white !important;
  padding: 0 20px !important;
}
.quick_reply  input{
  padding: 0 16px 0 40px!important
}
.quick_reply svg{
  left: 16px;
  top: 15px;
}
.time_select{
  width: 100% !important;
}
.time_select input{
  padding: 0 10px !important;
  color: #133159;
}
.time_select svg{
  position: absolute;
  right: 15px;
  top: 20px;
}
/* ===========Upload img css=========== */
.upload_img{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;
}
.upload_img .text{
  margin-right: 100px;
}
.upload_img span i{
  margin-left: 20px;
}
/* =========scrollbar css============= */
.note_tab_content,
.activities_tab,
.conversation_container,
.log_tab_content,
.files_tab_content{
  height: 700px !important;
  overflow-y: auto;
}
.note_tab_content.awesome__scroll_bar::-webkit-scrollbar-thumb,
.activities_tab.awesome__scroll_bar::-webkit-scrollbar-thumb,
.conversation_container.awesome__scroll_bar::-webkit-scrollbar-thumb,
.log_tab_content.awesome__scroll_bar::-webkit-scrollbar-thumb,
.files_tab_content.awesome__scroll_bar::-webkit-scrollbar-thumb{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  background-color: #133159;
}
.note_tab_content.awesome__scroll_bar::-webkit-scrollbar-track,
.activities_tab.awesome__scroll_bar::-webkit-scrollbar-track,
.conversation_container.awesome__scroll_bar::-webkit-scrollbar-track,
.log_tab_content.awesome__scroll_bar::-webkit-scrollbar-track,
.files_tab_content.awesome__scroll_bar::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 30%);
  border-radius: 10px;
  background-color: white;
}

/* =========Modal css=========== */
.select_video_modal{
  border-radius: 10px 0px 0px 10px;
  max-width: 1170px;
  left: unset !important;
  overflow-y: unset;
  position: fixed;
  right: 0;
  background-color: #fafafa;
  padding: 0;
  max-height: 70%;
  width: 55%;
  margin: auto;
  will-change: top, opacity;
}
.top_content {
  border-bottom: 1px solid #E7EFF0;
}
.sub_title span{
  cursor: pointer;
}

.sub_title h2 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  color: var(--dark_blue);
  margin: 0;
  margin-left: 10px;
}

.cross_button {
  background: var(--red);
  width: 26px;
  height: 26px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 15px;
}
.serach_input{
  position: relative;
  margin-left: auto;
  margin-right: 20px;
}
.serach_input input{
  background: #FFFFFF;
  border: 1px solid rgba(19, 49, 89, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  font-size: 16px !important;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.35);
  padding: 15px 57px 15px 20px !important;
  margin: 0px !important;
  height: 40px !important;
}
.serach_input input:focus{
  border-bottom:1px solid rgba(19, 49, 89, 0.35) !important;
  box-shadow: unset !important;
}
.serach_input .search_icon{
  position: absolute;
  top: 5px;
  right: 9px;
  border-left: 1px solid rgba(19, 49, 89, 0.35);
  padding-left: 6px;
  height: 30px;
  padding-top: 5px;
  cursor: pointer;
}

.video_folders{
  padding: 0 30px;
}
.video_folders ul li{
  margin-right: 23px;
}
.video_folders ul li h6{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #133159;
  margin: 0;
  text-transform: capitalize;
}
.video_folders ul li p{
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  color: rgba(19, 49, 89, 0.65);
  margin: 0;
}
.video_thumbnails{
  margin: 0 17px;
  padding-bottom: 2px;
}
.video_thumbnails .card{
  background: unset !important;
  box-shadow: unset !important;
  position: relative;
  height: 198px;
  margin: 0px 15px 15px 11px;
  padding: 0 !important;
}
.video_thumbnails .video_thumbnails_card:hover::before{
  opacity: 1;
}
.video_thumbnails .video_thumbnails_card::before {
  content: '';
  background: rgba(60, 126, 243, 0.5);
  opacity: 0;
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  transition: all 0.4s linear;
}

.video_thumbnails .card .play_icon{
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  height: 30px;
  opacity: 0;
  transition: all 0.4s linear;
  visibility: hidden;
}
.no-gutter{
  padding: 0 !important;
}

.no-gutter .card-image img{
  max-height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  top: unset !important;
  bottom: unset !important;
  left: unset !important;
  right: unset !important;
  height: 198px;
}
.no-gutter .card_content {
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
}
.video_thumbnails .card-image::before{
  content: '';
  background: rgba(60, 126, 243, 0.5);
  opacity: 0;
}
.video_thumbnails .card-image:hover{
  opacity: 1;
}
.video_thumbnails .card:hover span.play_icon {
  opacity: 1;
  visibility: visible;
}
.video_thumbnails .card:hover .card_content .right_icons {
  opacity: 1;
  visibility: visible;
}
.video_thumbnails_scroll.awesome__scroll_bar{
  height: 500px;
  overflow-y: auto;
  padding: 0px !important;
}
.video_thumbnails_scroll.awesome__scroll_bar::-webkit-scrollbar-thumb{
  background-color: rgba(19, 49, 89, 0.5) !important;
}
.video_thumbnails_scroll.awesome__scroll_bar::-webkit-scrollbar-track{
  background-color: white !important;
}
.card_content .card-title{
  font-weight: 500;
  font-size: 10.6978px;
  line-height: 18px;
  color: #FFFFFF !important;
  position: relative !important;
  margin: unset !important;
  top: unset !important;
  padding: 0 !important;
}
.card_content .right_icons{
  opacity: 0;
  visibility: hidden;
  transition: all 0.4s linear;
}
.card_content .right_icons span{
  color: white;
  font-weight: normal;
  font-size: 10px;
  line-height: 17px;
  display: inline-flex;
  margin-right: 5px;
}


/* ===============Manage massage modal css=============== */
.manage_massage_modal{
  border-radius: 10px 0px 0px 10px;
  max-width: 1170px;
  position: fixed;
  right: 0;
  background-color: #fafafa;
  padding: 0;
  max-height: 70%;
  width: 55%;
  margin: auto;
  overflow-y: auto;
  will-change: top, opacity;
}
.manage_modal_input_fields{
  padding-right: 50px;
}
.manage_modal_drp input{
  border: 1px solid rgba(19, 49, 89, 0.35) !important;
  box-sizing: border-box !important;
  border-radius: 5px !important;
  position: relative !important;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 24px !important;
  letter-spacing: 0.01em;
  color: rgba(19, 49, 89, 0.65);
  padding: 10px 10px !important;
  height: 40px !important;
}
.manage_modal_drp{
  margin-bottom: 7px !important;
  margin-right: 20px;
}
.manage_modal_drp .caret{
  display: none;
}
.manage_modal_drp .drp_arrow_icon{
  position: absolute;
  right: 12px;
  top: 11px;
}
.manage_modal_input_fields a{
  background: rgb(60, 126, 243);
  border-radius: 5px;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  color: white;
  padding: 5px 32px;
}
.manage_modal_input_fields a span{
  margin-right: 10px;
}

.modal_thumbnail .manage_modal_card{
  background: #F6F7FB !important;
  border-radius: 5px;
  padding: 15px !important;
  position: relative;
  overflow: hidden;
  padding-bottom: 25px !important;
  height: 320px !important;
  margin: 0 0 20px 0 !important;
}
.manage_modal_card_content .card-title {
  font-weight: 500;
  font-size: 10.6978px;
  line-height: 18px;
  color: #FFFFFF !important;
  position: relative !important;
  margin: unset !important;
  bottom: 30px !important;
  padding: 15px !important;
}
.modal_thumbnail .manage_modal_card a{
  background: #3C7EF3;
  font-weight: 500;
  font-size: 13.7254px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;
  padding: 10px 20px;
  text-transform: capitalize;
  border-radius: 5px;
}
.manage_modal_card p::before{
  content: "";
  position: absolute;
  right: 156px;
  top: 28px;
  z-index: -1;
  border-left: 5px solid #133159;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-top: 5px solid #133159;
}
.manage_modal_card p::after{
  content: "";
  position: absolute;
  right: 9px;
  top: 29px;
  z-index: -1;
  border-left: 5px solid #133159;
  border-right: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-top: 5px solid #133159;
}





/* ==============Right Sidebar Collapsable css================= */
.right_sidebar_active {}

.main__content .ContactModule-container-parent-class .main-inbox_column {
  transition: all 0.5s ease-in-out;
  width: 75% !important;
}

.inbox-right-side {
  transition: all 0.5s ease-in-out;
  width: 0% !important;
}

.right_sidebar_active .ContactModule-container-parent-class .main-inbox_column {
  width: 50% !important;
}

.inbox-right-side {
  width: 25% !important;
}


.side_content_row {
  overflow: hidden;
}
.main__content:not(.right_sidebar_active) .inbox-right-side {
  padding: 1% !important;
  margin: 0 !important;
  opacity: 0;
}

.ContactModule-container-parent-class .inbox-right-side .new-loader {
	position: absolute;
	left: 50%;
	top: 50px;
	transform: translate(-50%, -50%);
	width: 60px;
	height: 60px;
	display: block;
}

.ContactModule-container-parent-class .inbox-right-side .new-loader>div {
	margin: 0;
	height: 60px;
}

.ContactModule-container-parent-class .inbox-right-side .new-loader button {
	all: unset;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
	height: 60px;
	left: 50%;
	display: block;
	text-align: center;
}

.ContactModule-container-parent-class .inbox-right-side .new-loader .MuiCircularProgress-root {
	width: 60px !important;
	height: 60px !important;
	margin: 0 !important;
	top: unset;
	left: unset;
}


/* Custom Css */
.accordion__deal {
  background: #fff;
}

.accordion__deal .accordion__deal__expend {
  /* display: flex;
  justify-content: center;
  align-items: center; */
  text-align: center;
  margin: 15px 0;
}
.accordion__deal__expend a{
  /* padding:  3px 10px; */
  /* margin: 0 18px; */
}

.accordion__deal .MuiGrid-item{
  padding: 0 !important;
}

.accordion__deal .MuiGrid-item .justify-content-between{
  margin-bottom: 15px;
}

.accordion__deal .text-gray a{
  font-size: 14px;
  color: var(--gray);
  font-weight: 400;
}





.mms-image-in-details {
  width: 250px;
  height: auto;
  object-fit: cover;
  border-radius: 8px; 
  margin-bottom: 10px;
  display: block;
}

/* ============Responsive css============ */
@media screen and (max-width: 1560px){
  .modal_thumbnail .manage_modal_card{
    height: 256px !important;
  }
}
@media screen and (max-width: 1366px){
  .modal_thumbnail .manage_modal_card{
    height: 230px !important;
  }
  .serach_input input{
    width: 220px;
  }
  .manage_modal_input_fields a{
    padding: 5px 15px;
  }
}
@media screen and (max-width: 1298px){
  .upload_img .text{
    margin-right: 70px;
  }
  .modal_thumbnail .manage_modal_card a{
    padding: 10px 10px;
  }
}
@media screen and (max-width: 1250px){
  .upload_img .text{
    margin-right: 70px;
  }
  .modal_thumbnail .manage_modal_card a{
    padding: 10px 10px;
  }
  .serach_input{
    width: 180px;
  }
  .manage_modal_input_fields a{
    padding: 5px 8px;
  }
  .modal_thumbnail .manage_modal_card a{
    font-size: 12px;
  }
  .modal{
    width: 70%;
  }
}

@media screen and (max-width: 992px) {
  .main__content .ContactModule-container-parent-class .main-inbox_column{
    width: 100% !important;
    padding: unset !important;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .inbox-right-side{
    margin-top: 20px !important;
    width: 100% !important;
  }
  .lead_owner_collapsible .collapsible-body,
  .campaigns__collapsible{
    padding: 20px !important;
  }
  .upload_img .text{
    margin-right: 50px;
  }
  .switch-icon{
    display: none;
  }
  .modal_thumbnail .manage_modal_card{
    height: 280px !important;
  }
  .manage_modal_input_fields a{
    font-size: 11px;
  }
  .user_profile_icons .single_icon i{
    font-size: 18px;
  }
  .tabs .tab a.tabs-items{
    font-size: 16px !important;
  }
  .center_field_top .tab a p{
    font-size: 15px;
  }
  .activities_tab .activities_tab_header a{
    font-size: 14px;
  }
  .direct_mail_pick_btns a{
    min-width: 110px;
    font-size: 12px;
    padding: 10px 10px !important;
  }
  .single_message .message_header p{
    font-size: 10px;
  }
  .single_message .message_header p{
    font-size: 12px;
  }
  .lead_owner_collapsible .collapsible-header h5{
    font-size: 13px;
  }
}
.send-button-area {
  padding: 0 20px;
  margin-top: 10px;
}


/* Complete Form */
.complete__form .form__builder-title{
  color: var(--dark_blue);
  margin-bottom: 1.5rem !important;
}
.complete__form .form__builder-date{
  color: var(--gray) !important;
  margin-bottom: 1.5rem !important;
  font-size: 10px;
}



/* Contact Middle Css Custom Start */


body{
  font-family: var(--poppins) !important;
}
.white.contact__middle__inbox__wr {
  padding: 15px 11px;
}
.contact__middle__inbox__top{
  padding: 0px !important;
  box-shadow: 0px 0px rgba(0,0,0,0.0) !important;
  border-bottom: 1px solid #f6f7fb;
    padding-bottom: 10px !important;
    margin-bottom: 10px;
}
.contact__middle__inbox__ul{}
.contact__middle__inbox__input .select-wrapper{
  border: 1px solid rgba(60, 126, 243, 0.1) !important;
    box-sizing: border-box !important;
    border-radius: 5px !important;
    position: relative !important;
    font-size: 14px !important;
    line-height: 18px !important;
    font-weight: 500;
    padding: 10px 8px 14px!important;
    color: #3C7EF3 !important;
    height: 48px !important;
}
.contact__middle__inbox__input .MuiSelect-select {
  width: 100% !important;
  height: 100% !important;
}
.contact__middle__inbox__input .MuiSelect-select:focus {
  border-radius: 0;
  background-color: rgba(0, 0, 0,0.0) !important;
  width: 100% !important;
  height: 100% !important;
}
.contact__middle__inbox__input .select-wrapper .contact__middle__inbox__select__innner::before,
.contact__middle__inbox__input .select-wrapper .contact__middle__inbox__select__innner::after {
  display: none;
}
.contact__middle__inbox__select__innner .MuiSelect-selectMenu {
  overflow: visible; 
  min-height: auto;
  white-space: wrap;
  text-overflow: initial;
  color: #3C7EF3 !important;
  min-width: 100% !important;
  display: flex;
  
}
.contact__middle__inbox__input .select-wrapper .MuiInputBase-root{
  font-size: 14px;
  margin-top: -14px;
  text-transform: capitalize;
}


.contact__middle__inbox__top__col0{
  width: 41% !important;
 
}
.contact__middle__inbox__top li.tab{
  height: auto;
}

/* Contact Middle Css Custom End */





@media (min-width: 1366px) and (max-width: 1599px) {
  .personiliz-input-field {
    width: 50% !important;
  }
}
@media (min-width: 1366px) and (max-width: 1599px){
  .personiliz-input-field {
    width: 200px !important;
  }
}

  .number-input-field {
    position: relative;
  }
.quick-reply a {
  width: 168px;
  height: 40px;
  border: 1px solid rgba(60, 126, 243, 0.1);
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #133159;
  display: inline-block;
  line-height: 40px;
  text-align: center;
}
@media (min-width: 1366px) and (max-width: 1599px){
  .quick-reply a {
    width: 100%;
    font-size: 11px;
  }
  .quick-reply svg {
    margin-right: 1px;
    width: 7px;
  }
}
.send-message-btn {
  padding-right: 20px;
  padding-bottom: 30px;
}
@media (min-width: 1366px) and (max-width: 1599px){
  .send-message-btn {
    padding-bottom: 15px;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
  }
}


button.btn.btn-sm.btn-primary.letter-button.mr-1.active {
  background: #3977e6;
}

/* for task */
.task-list-wrapper .m-widget2 .m-widget2__item.task-list .info span {
  font-size: 14px;
  padding-bottom: 4px;
  display: block;
  font-weight: 300;
}

.d-block { 
  display: block !important;
}

.notItemsFound,
.contact__middle__inbox__wr .lodar_f_pdf {
    height: 200px !important;
    background: #f8f8f8;
}
.notItemsFound {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #133159;
    text-transform: capitalize;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

/* .awesome__scroll_bar.customScrollbar__height {
  height: 340px;
} */


/* Update Style */ 
.single__contact_height {
     /* height: 100%; */
     /* height: calc(100% - 26px); */
}

.center_field_tabs.white.contact__middle__inbox__wrapper > .row,
.contact__middle__inbox__wrapper {
  height: 100%;
}
#ContactModule-container {
    height: calc(100% - 25px); 
}

.single__contact_height .side_content_row {
    height: 100%;
}

.single__contact_height .contact_center_field.main-inbox_column,
.single__contact_height .contact__middle__inbox__wr,
.single__contact_height .side_content_row .contact_details.white{
    height: 100%;
}

.single__contact_height .contact__middle__inbox__wr .row.m-0{
    height: 100%
}

.single__contact_height #conversation_tab  {
    /* height: calc(100% - 70px); */
    height: calc(100% - 90px);
}

.single__contact_height .contact__middle__inbox__conversation__container {
    height: 100% !important;
}

/* .single__contact_height .lead_owner_collapsible.white.inbox-right-side {
    height: 100%;
} */

.single__contact_height .row.mt-4.userprofile__tabs {
    height: calc(100% - 200px);
}
.single__contact_height .use__tabs__panel .MuiBox-root {
    height: 100%;
}
 .single__contact_height .use__tabs__panel,
.single__contact_height .MuiBox-root {
    height: 100%;
}
.single__contact_height div#contact {
    height: calc(100% - 50px);
}
.single__contact_height .awesome__scroll_bar.customScrollbar__height {
    height: calc(100% - 130px);
}

.ContactCustomClass {
    height: calc(100% - 100px);
}

/* Update at 5.8.21 */

.note_tab_content .notItemsFound {
  background: #fff;
  margin: 1.75rem;
}
.beforeEvent {
  font-size: 16px !important;
  line-height: 24px !important;
  letter-spacing: 0.01em !important;
  color: var(--dark_blue);
  margin-left: 5px !important;
}
.activity__modal_overflow.update [type="checkbox"].filled-in:not(:checked)+span:not(.lever):after,
.activity__modal_overflow.update [type="checkbox"].filled-in:checked+span:not(.lever):after {
  top: 1px;
}
.v2_single__contact_campaigns .MuiCollapse-root.MuiCollapse-entered {
  min-height: unset !important;
  max-height: unset !important;
  overflow: unset;
}
body .v2_single__contact_campaigns .colums-filter {
  display: inline-flex;
  align-items: center;
  position: static !important;
  all: unset;
}
body .v2_single__contact_campaigns .colums-filter > div {
 position: static !important;
 /* all: unset; */
 height: 20px;
    border-radius: 10px;
}
.v2_single__contact_campaigns .colums-filter .colums-filter ul#menu-list-grow {
  right: 0 ;
}
.v2_single__contact_campaigns .colums-filter .colums-filter ul#menu-list-grow {
  height: auto;
  max-height: 200px;
  overflow-y: auto;
  right: 0;
  left: auto !important;
  top: unset;
}
body .v2_single__contact_campaigns .colums-filter > div.bg-green {
  background: var(--green);
} 

@media (max-width: 1441px){
  .inbox-right-side h6,.inbox-right-side .running_btn_campaigns span{
    font-size: 11px;
  }
}

.customGridWrapper .MuiGrid-container .MuiGrid-root.campaign-list-to-assign-contact {
  max-width: 100%;
  flex-basis: 100%;
}
