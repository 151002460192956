.contact__update__more__info .preview__date__field__wr {
    width: 100%;
    /* border-bottom: 1px solid #9e9e9e; */
    margin: 6px 0px;
    border: 1px solid var(--gray_dark)!important;
    color: var(--gray);
    box-shadow: none!important;
    padding-left: 15px!important;
    box-sizing: border-box!important;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    display: flex;
    align-items: center;
    height: 42px;
}
.contact__update__more__info .preview__date__field__wr .preview__date__field{
  width: 100%;
  margin: 6px 0 0 -8px;
}
.contact__update__more__info .preview__date__field__wr .preview__date__field .MuiFormControl-root.MuiTextField-root.MuiFormControl-marginNormal {
  width: 100%;
}

.contact__update__more__info .massage_send_close_button{
  margin-left: 0 !important;
}

.contact__update__more__info .preview__date__field__wr .preview__date__field button.MuiButtonBase-root.MuiIconButton-root {
  position: absolute;
  top: 0;
  right: 0;
}

.contact__update__more__info .preview__date__field__wr .preview__date__field svg {
  position: unset;
}
.contact__update__more__info .preview__date__field__wr .preview__date__field span.ar__req {
  top: 15px !important;
}
.contact__update__more__info .preview__date__field__wr .preview__date__field .MuiInputAdornment-root.MuiInputAdornment-positionEnd {
  margin-left: 0px !important;
}

.contact__update__more__info .preview__date__field__wr .preview__date__field button:focus {
  background-color: rgba(0,0,0,0.0) !important ;
}

.contact__update__more__info .preview__date__field__wr .preview__date__field .MuiFormControl-root.MuiFormControl-marginNormal {
  margin-top: 0;
  margin-bottom: 0;
}

.contact__update__more__info .preview__date__field__wr .no__date__field__section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 8px 0 0px;
}
.contact__update__more__info .preview__date__field__wr .no__date__field__section span{
  display: inline-flex;
}

.contact__update__more__info .preview__date__field__wr .no__date__field__section span {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #D1D1D1;
}
.contact__update__more__info .preview__date__field__wr .no__date__field__section .add__new__date__icon{
  display: inline-flex;
}

.contact__update__more__info .preview__date__field__wr .no__date__field__section .add__new__date__icon {
    margin-left: 10px;
    cursor: pointer;
}

.contact__update__more__info .preview__date__field__wr .no__date__field__section .add__new__date__icon svg circle {
    fill: #000000 !important;
}

.contact__update__more__info .preview__date__field__wr .no__date__field__section .add__new__date__icon svg path {
    fill: #ffffff !important;
}


.contact__update__more__info .Update__contact__modal_title{
  font-size: 14px;
  color: var(--dark_blue);
  padding-bottom: 4px;
}
.contact__update__more__info .Update__contact__modal_input{
  border: 1px solid var(--gray_dark)!important;
  color: var(--gray);
  box-shadow: none!important;
  padding-left: 15px!important;
  box-sizing: border-box!important;
  border-radius: 5px;
  background: #fff;
  font-size: 14px;
}

.contact__update__more__info .update__date__wrapper__extra{
  display: flex;
  grid-gap: 16px;

}
.contact__update__more__info .update__date__wrapper__extra .update__date__wrapper__extra__left{
  flex: 1;
}
.contact__update__more__info .update__date__wrapper__extra .update__date__wrapper__extra__right{
  flex: 1;
}