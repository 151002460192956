.ContactModule-container-parent-class .rdrCalendarWrapper {
  box-sizing: border-box;
  background: #ffffff;
  display: inline-flex;
  flex-direction: column;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ContactModule-container-parent-class .rdrDateDisplay{
  display: flex;
  justify-content: space-between;
}

.ContactModule-container-parent-class .rdrDateDisplayItem{
  flex: 1 1;
  width: 0;
  text-align: center;
  color: inherit;
}

.ContactModule-container-parent-class .rdrDateDisplayItem + .rdrDateDisplayItem{
    margin-left: 0.833em;
  }

.ContactModule-container-parent-class .rdrDateDisplayItem input{
    text-align: inherit
  }

.ContactModule-container-parent-class .rdrDateDisplayItem input:disabled{
      cursor: default;
    }

.ContactModule-container-parent-class .rdrMonthAndYearWrapper {
  box-sizing: inherit;
  display: flex;
  justify-content: space-between;
}

.ContactModule-container-parent-class .rdrMonthAndYearPickers{
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ContactModule-container-parent-class .rdrNextPrevButton {
  box-sizing: inherit;
  cursor: pointer;
  outline: none;
}

.ContactModule-container-parent-class .rdrMonths{
  display: flex;
}

.ContactModule-container-parent-class .rdrMonthsVertical{
  flex-direction: column;
}

.ContactModule-container-parent-class .rdrMonthsHorizontal > div > div > div{
  display: flex;
  flex-direction: row;
}

.ContactModule-container-parent-class .rdrMonth{
  width: 27.667em;
}

.ContactModule-container-parent-class .rdrWeekDays{
  display: flex;
}

.ContactModule-container-parent-class .rdrWeekDay {
  flex-basis: calc(100% / 7);
  box-sizing: inherit;
  text-align: center;
}

.ContactModule-container-parent-class .rdrDays{
  display: flex;
  flex-wrap: wrap;
}

.ContactModule-container-parent-class .rdrInfiniteMonths{
  overflow: auto;
}

.ContactModule-container-parent-class .rdrDateRangeWrapper{
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ContactModule-container-parent-class .rdrDateInput {
  position: relative;
}

.ContactModule-container-parent-class .rdrDateInput input {
    outline: none;
  }

.ContactModule-container-parent-class .rdrDateInput .rdrWarning {
    position: absolute;
    font-size: 1.6em;
    line-height: 1.6em;
    top: 0;
    right: .25em;
    color: #FF0000;
  }

.ContactModule-container-parent-class .rdrDay {
  box-sizing: inherit;
  width: calc(100% / 7);
  position: relative;
  font: inherit;
  cursor: pointer;
}

.ContactModule-container-parent-class .rdrDayNumber {
  display: block;
  position: relative;
}

.ContactModule-container-parent-class .rdrDayNumber span{
    color: #1d2429;
  }

.ContactModule-container-parent-class .rdrDayDisabled {
  cursor: not-allowed;
}

@supports (-ms-ime-align: auto) {
    .ContactModule-container-parent-class .rdrDay {
    flex-basis: 14.285% !important;
  }
}

.ContactModule-container-parent-class .rdrSelected, .rdrInRange, .rdrStartEdge, .rdrEndEdge{
  pointer-events: none;
}

.ContactModule-container-parent-class .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview{
  pointer-events: none;
}

.ContactModule-container-parent-class .rdrDateRangePickerWrapper{
  display: inline-flex;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.ContactModule-container-parent-class .rdrStaticRanges{
  display: flex;
  flex-direction: column;
}

.ContactModule-container-parent-class .rdrStaticRange{
  font-size: inherit;
}

.ContactModule-container-parent-class .rdrInputRange{
  display: flex;
}