.ContactModule-container-parent-class .batches_report_table{}

/* ========Batches Report Table Layout Css=========== */
.ContactModule-container-parent-class .batches_report_table{
    table-layout: fixed !important;
    width: 100% !important;
}
.ContactModule-container-parent-class .batches_report_table thead tr th:first-child{
    max-width: 15% !important;
    width: 15% !important;
}
.ContactModule-container-parent-class .batches_report_table thead tr th:nth-child(2){
    max-width: 15% !important;
    width: 15% !important;
}
.ContactModule-container-parent-class .batches_report_table thead tr th:nth-child(3){
    max-width: 13% !important;
    width: 13% !important;
}
.ContactModule-container-parent-class .batches_report_table thead tr th:nth-child(4){
    max-width: 20% !important;
    width: 20% !important;
}
.ContactModule-container-parent-class .batches_report_table thead tr th:nth-child(5){
    max-width: 25% !important;
    width: 25% !important;
}
.ContactModule-container-parent-class .batches_report_table thead tr th:last-child{
    max-width: 10% !important;
    width: 10% !important;
    text-align: center;
}
.ContactModule-container-parent-class .batches_report_table .header th{
    font-size: 14px !important;
    line-height: 20px !important;
}
.ContactModule-container-parent-class .batches_report_table tbody tr td span,
.ContactModule-container-parent-class .batches_report_table tbody tr td{
    font-size: 14px !important;
    font-weight: 500 !important;
    line-height: 20px !important;
}

.ContactModule-container-parent-class .batches_report_table tbody tr td:last-child{
    text-align: center;
}

.ContactModule-container-parent-class .table__row__expand__icon{
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    background: var(--dark_blue);
    flex-shrink: 0 !important;
    font-size: 13px !important;
}
.ContactModule-container-parent-class .drip__action__btn{
    display: flex;
    justify-content: center;
    grid-gap: 5px;
    align-items: center;
}
.ContactModule-container-parent-class .drip__completed__field{
    display: flex;
    grid-gap: 10px;
}
.ContactModule-container-parent-class .drip__completed__field .drip__completed__field__timeline{
    cursor: pointer;
    color: #039be5;
}
.ContactModule-container-parent-class .drip__total__field{
    display: flex;
    grid-gap: 6px;
}
.ContactModule-container-parent-class .drip__completed__uploaded{
    color: var(--green);
}
.ContactModule-container-parent-class .drip__completed__invalid{
    color: var(--red);
}
.ContactModule-container-parent-class .drip__completed__processed{
    color: var(--blue-2);
}

.ContactModule-container-parent-class .drip__expandable__row__wrapper{
    text-align: left;
    padding: 12px 0;
}
.ContactModule-container-parent-class .drip__expandable__row__common{
    display: flex;
    align-items: center;
    grid-gap: 5px;
}
.ContactModule-container-parent-class .drip__expandable__row__common +.drip__expandable__row__common{
    padding: 5px 0 0 0;
}
.ContactModule-container-parent-class .drip__expandable__row__common .drip__expandable__left__title{
    font-weight: 700 !important;
}
.ContactModule-container-parent-class .drip__expandable__row__common .drip__expandable__left__send {
    display: flex;
    align-items: center;
    grid-gap: 5px;
}
.ContactModule-container-parent-class .drip__expandable__left__send__wrapper{
    align-items: flex-start !important;
}
.ContactModule-container-parent-class .drip__expandable__left__send .each__send__on__day{
    display: flex;
    color: #fff;
    border-radius: 4px;
    width: 45px;
    height: 25px;
    justify-content: center;
    align-items: center;
}
.ContactModule-container-parent-class .drip__expandable__row__td{
    background: rgba(194,206,229,0.1);
}
.ContactModule-container-parent-class .drip__expandable__left__send  .send__on__active{
    background: var(--green2);
}
.ContactModule-container-parent-class .drip__expandable__left__send  .send__on__inactive{
    background: var(--red);
}
.ContactModule-container-parent-class .batches_report_table .tbody_content .table_title_text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ContactModule-container-parent-class .drip_batch__wrapper__v2{
    display: flex;
    grid-gap: 16px;
}
.ContactModule-container-parent-class .drip_batch__wrapper__v2__1{
    flex:1;
}
.ContactModule-container-parent-class .drip_batch__wrapper__v2__2{
    flex:1;
}
.ContactModule-container-parent-class .drip_batch__wrapper__v2__3{
    flex:1;
}
.ContactModule-container-parent-class .drip_batch__wrapper__v2__3 .form-control,
.ContactModule-container-parent-class .drip_batch__wrapper__v2__2 .form-control{
    background-color: rgba(255,255,255,0.9);
    width: 100%;
    padding: 5px;
    border: 1px solid #f2f2f2;
    border-radius: 2px;
    height: 3rem;
}

.frame__wise__drip__report .drip__report__action__btn{
    background-color: var(--green);
    color: var(--white);
    border-radius: 4px;
    padding: 5px 8px;
    display: inline-block;
    cursor: pointer;
}
.frame__wise__drip__report .pagination___body__wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
}
.frame__wise__drip__report .pagination___body__wrapper .pagination__input__area{
    display: flex;
    align-items: center;
}

.frame__wise__drip__report .pagination___body__wrapper .pagination___input___field{
    border-radius: 5px;
    padding: 6px 4px;
    margin-left: 10px;
    display: block;
    border: 2px solid #3C7EF3;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 50px;
    height: 40px;
}
.frame__wise__drip__report .pagination___body__wrapper .pagination___separator{
    border-radius: 5px;
    padding: 6px 13px;
    margin-left: 10px;
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #3C7EF3;
}
.frame__wise__drip__report .pagination___body__wrapper .pagination___total__pages{
    border-radius: 5px;
    padding: 6px 13px;
    display: block;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #3C7EF3;
}
.ContactModule-container-parent-class .drip__batch__modal__sendOn{
    display: flex;
    grid-gap: 8px;
}
.ContactModule-container-parent-class .drip__batch__modal__sendOn .sendOnButtons{
    display: flex;
    grid-gap: 5px;
}

.ContactModule-container-parent-class .drip__batch__modal__sendOn  .each__send__on__day{
    display: flex;
    color: #fff;
    border-radius: 4px;
    width: 45px;
    height: 25px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.ContactModule-container-parent-class .drip__batch__modal__sendOn .send__on__active{
    background: var(--green2);
}
.ContactModule-container-parent-class .drip__batch__modal__sendOn .send__on__inactive{
    background: var(--red);
}
.ContactModule-container-parent-class .drip__batch__modal__sendOn .form-control-label{
    color: #9e9e9e;
    font-size: 1rem;
}
.frame__wise__drip__report .drip__frame__contact__table,
.frame__wise__drip__report .drip__frame__table{
    height: calc(100vh - 163px);
    padding-bottom: 24px;
    overflow-y: auto;
    overflow-x: hidden;
}
.empty__table__container{
    text-align: center;
}