.list__setting_container .input-field label {
    font-size: 16px;
    line-height: 24px;
    position: static;
    -webkit-transform: none;
    transform: none;
    color: var(--dark_blue);
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 7px;
}
.list__setting_container .input-field input, .list__setting_container .input-field textarea {
    width: 100%;
    border: 1px solid rgba(19,49,89,.3);
    border-radius: 5px;
    padding: 0 10px;
    margin-top: 3px;
    font-family: var(--poppins);
    font-size: 16px;
    line-height: 24px;
    color: rgba(19,49,89,.35);
    font-weight: 400;
}
.list__setting_container .input-field .input-select {
    background: #f6f7fb;
    border: 1px solid #e5e8ef;
    border-radius: 5px;
    width: 100%;
    padding: 5px 10px;
}