.ContactModule-container-parent-class .contact___details___unsubscribed__details{
  background-color: #ffcc00;
  margin-top: 20px;
  border-radius: 10px 10px 0px 0px;
  padding: 7px 12px 10px 12px!important;
}
.ContactModule-container-parent-class .contact___details___unsubscribed__details .unsub__title{
  font-weight: 500;
  font-size: 16px;
  color: var(--red);
}
.ContactModule-container-parent-class .contact___details___unsubscribed__details .unsub__hints{
    white-space: normal;
    font-size: 14px;
    color: #515861;
    word-break: break-word;
}
.ContactModule-container-parent-class .contact___details___unsubscribed__details .unsub__hints__bold{
  font-weight: 500;
  color: var(--dark_blue);
}