.ContactModule-container-parent-class .upload__form__start__wrapper{
    display: flex !important;
    align-items: center !important;
    width: 767px;
    margin: 0 auto 28px auto !important;
}
.ContactModule-container-parent-class .upload__form__start__wrapper .upload__form__start__flex{
    flex: 1;
}

.ContactModule-container-parent-class .upload__form__start__wrapper .assign_user__time__zone__wr{
    flex: 1;

}

.ContactModule-container-parent-class .upload__form__start__wrapper .upload__form__start__flex__1st input:focus{
    box-shadow: 0 0 0 rgba(0,0,0,0.0) !important;
}
.ContactModule-container-parent-class .upload__form__start__wrapper .upload__form__start__flex__1st input{
    height: 45px !important;
    margin: 0 !important;
}

.ContactModule-container-parent-class .batch__process__container__v2{
    column-gap: 20px;
    width: 767px;
}
.ContactModule-container-parent-class .batch__process__container__v2 .batch__process__container__v2__single{
    flex: 1;
}

.ContactModule-container-parent-class .common__label__v3{
    font-size: 18px !important;
    color: var(--dark_blue) !important;
}

.ContactModule-container-parent-class .label__borer__bottom__wr__v2{
    border-bottom: 2px solid var(--dark_blue);
    margin-bottom: 8px !important;
}
